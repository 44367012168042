import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About';
import RideList from './components/RideList';
import UserRegistration from './components/UserRegistration';
import RideDetails from './components/RideDetails';
import SeriesParticipation from './components/SeriesParticipation';
import Login from './components/Login';
import NavBar from './components/NavBar';
import Breadcrumbs from './components/Breadcrumbs';
import ClubOwnerDashboard from './components/ClubOwnerDashboard';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import NewClub from './components/NewClub';
import NewRide from './components/NewRide';
import OurMission from './components/OurMission';
import MeetOurTeam from './components/MeetOurTeam';
import ExploreOurHistory from './components/ExploreOurHistory';
import Contact from './components/Contact';
import Faq from './components/Faq';
import ClubOwnerRides from './components/ClubOwnerDashboard/Rides';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import RulesAndRegulations from './components/RulesAndRegulations';
import MyRides from './components/MyRides';
import Profile from './components/Profile';
import Settings from './components/Settings';
import PrivateRoutes from './components/PrivateRoutes';
import PublicProfile from './components/PublicProfile';
import { AuthProvider } from './context/AuthContext';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ResetPassword';
import Clubs from './components/Clubs';
import ClubDetails from './components/Clubs/ClubDetails';
import ClubPaymentsOnboarding from './components/ClubOwnerDashboard/ClubPaymentsOnboarding';
import PaymentsDashboard from './components/payments/PaymentsDashboard';
import ClubRides from './components/ClubRides/ClubRides';
import AdminRidersList from './components/RideAdmin/AdminRidersList';
import RidePayment from "./components/RideAdmin/RidePayment";
import MasterAdminPage from './components/RideAdmin/MasterAdminPage';
import RideDetailsAdmin from './components/RideAdmin/RideInformationEditor';
import RideDetailsEditor from './components/RideAdmin/RideDetailsEditor';
import ControlPointsEditor from './components/RideAdmin/ControlPointsEditor/ControlPointsEditor';
import AllChatsPage from './components/Chat/allChats';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState('forgotPassword');

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem('isLoggedIn');
    if (isLoggedIn && window.location.pathname === '/') {
      navigate('/dashboard');
    }

    const updateTitle = () => {
      let title = "Ultra Rides";
      const path = location.pathname;

      if (path === "/") title = "Home | Ultra Rides";
      else if (path.startsWith("/about")) title = "About Us | Ultra Rides";
      else if (path.startsWith("/rides")) title = "Rides | Ultra Rides";
      else if (path.startsWith("/create-account")) title = "Create Account | Ultra Rides";
      else if (path.startsWith("/login")) title = "Login | Ultra Rides";
      else if (path.startsWith("/reset-password")) title = "Reset Password | Ultra Rides";
      else if (path.startsWith("/forgot-password")) title = "Forgot Password | Ultra Rides";
      else if (path.startsWith("/clubs")) title = "Clubs | Ultra Rides";
      else if (path.startsWith("/payments")) title = "Payments | Ultra Rides";
      else if (path.startsWith("/dashboard")) title = "Dashboard | Ultra Rides";
      else if (path.startsWith("/owner-dashboard")) title = "Owner Dashboard | Ultra Rides";
      else if (path.startsWith("/myrides")) title = "My Rides | Ultra Rides";
      else if (path.startsWith("/profile")) title = "Profile | Ultra Rides";
      else if (path.startsWith("/settings")) title = "Settings | Ultra Rides";
      else if (path.startsWith("/chat")) title = "Chats | Ultra Rides";
      else if (path.startsWith("/our-mission")) title = "Our Mission | Ultra Rides";
      else if (path.startsWith("/meet-our-team")) title = "Meet Our Team | Ultra Rides";
      else if (path.startsWith("/history")) title = "Our History | Ultra Rides";
      else if (path.startsWith("/contact")) title = "Contact Us | Ultra Rides";
      else if (path.startsWith("/faq")) title = "FAQ | Ultra Rides";
      else if (path.startsWith("/privacy-policy")) title = "Privacy Policy | Ultra Rides";
      else if (path.startsWith("/terms-of-service")) title = "Terms of Service | Ultra Rides";
      else if (path.startsWith("/rules-and-regulations")) title = "Rules & Regulations | Ultra Rides";
      else if (path.startsWith("/rides/")) {
        const rideId = path.split("/")[2];
        title = `Ride Details | ${rideId} | Ultra Rides`;
      } else if (path.startsWith("/clubs/")) {
        const clubId = path.split("/")[2];
        title = `Club Details | ${clubId} | Ultra Rides`;
      }

      document.title = title;
    };

    updateTitle();
  }, [navigate, location]);

  const isPublicProfilePath = /^\/athletes\/\d+\/profile$/.test(location.pathname);

  return (
    <AuthProvider>
      <div className="App bg-background min-h-screen text-textPrimary flex flex-col m-0 p-0">
        <NavBar />
        {!isPublicProfilePath && <Breadcrumbs />}
        <div className="content-container pt-0 flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/rides" element={<RideList />} />
            <Route path="/create-account" element={<UserRegistration />} />
            <Route path="/rides/:rideId" element={<RideDetails />} />
            <Route path="/rides/:rideId/admin" element={<MasterAdminPage />} />
            <Route path="/rides/:rideId/admin/riders" element={<AdminRidersList />} />
            <Route path="/rides/:rideId/admin/ride-payment" element={<RidePayment />} />
            <Route path="/rides/:rideId/admin/ride-information" element={<RideDetailsAdmin />} />
            <Route path="/rides/:rideId/admin/ride-details" element={<RideDetailsEditor />} />
            <Route path="/rides/:rideId/admin/control-points" element={<ControlPointsEditor />} />  {/* Corrected route */}
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword setStep={setStep} />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/clubs/:clubId" element={<ClubDetails />} />
            <Route path="/clubs/:clubId/rides" element={<ClubRides />} />
            <Route path="/clubs/ClubPaymentsOnboarding" element={<ClubPaymentsOnboarding />} />
            <Route path="/athletes/:userId/profile" element={<PublicProfile />} />
            <Route path="/payments" element={<PaymentsDashboard />} />

            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/owner-dashboard/rides" element={<ClubOwnerRides />} />
              <Route path="/clubs/series" element={<SeriesParticipation />} />
              <Route path="/clubs/new" element={<NewClub />} />
              <Route path="/clubs/:clubId/rides/new" element={<NewRide />} />
              <Route path="/myrides" element={<MyRides />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/mychats" element={<AllChatsPage />} />
              <Route path="/owner-dashboard" element={<ClubOwnerDashboard />} />
            </Route>

            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/meet-our-team" element={<MeetOurTeam />} />
            <Route path="/history" element={<ExploreOurHistory />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/rules-and-regulations" element={<RulesAndRegulations />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
