import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';

function Rides() {
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchRides = async () => {
    try {
      const response = await axios.get('/clubs/myclub/rides');
      const sortedRides = response.data.sort((a, b) => {
        const aDate = new Date(a.startDateTime);
        const bDate = new Date(b.startDateTime);
        return aDate - bDate;
      });
      setRides(sortedRides);
    } catch (error) {
      console.error('Error fetching rides:', error);
      setError('Error fetching rides.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRides();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-background-light rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-primary mb-4">Club Rides</h1>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b-2 border-gray-200 text-left">Name</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 text-left">Start Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {rides.map((ride, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-200">
                <a href={`/rides/${ride.rideId}`} className="text-blue-500 hover:underline">
                  {ride.name}
                </a>
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {ride.startDateTime ? new Date(ride.startDateTime).toLocaleString() : 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Rides;
