import React, { useState, useEffect } from 'react';
import Message from '../Message';

function RideRegistration({ state, registering, handleRegister, handleUnregister, isUserRegistered, registrationMessage, registrationClosingDateTime }) {
  const currentDateTime = new Date();
  const closingDateTime = new Date(registrationClosingDateTime);
  const [isRegistered, setIsRegistered] = useState(isUserRegistered);

  useEffect(() => {
    setIsRegistered(isUserRegistered);
  }, [isUserRegistered]);

  const isRegistrationClosed = currentDateTime > closingDateTime;

  const onRegister = async () => {
    await handleRegister();
    setIsRegistered(true);
  };

  const onUnregister = async () => {
    await handleUnregister();
    setIsRegistered(false);
  };

  return (
    <div className="mt-4 mb-4">
      {isRegistrationClosed ? (
        <button
          className="bg-gray-400 text-white py-2 px-4 rounded cursor-not-allowed mb-4"
          disabled
        >
          Registration Closed
        </button>
      ) : (
        <div>
          {isRegistered ? (
            <div className="flex items-center space-x-2">
              <p className="text-green-600 font-semibold">You are registered for this ride.</p>
              <button
                onClick={onUnregister}
                className="text-primary hover:text-primary-dark underline"
                disabled={registering}
              >
                {registering ? 'Processing...' : 'Click here to unregister'}
              </button>
            </div>
          ) : (
            <button
              onClick={onRegister}
              className="py-2 px-4 rounded bg-primary hover:bg-primary-dark text-white"
              disabled={registering}
            >
              {state.isAuthenticated
                ? registering
                  ? 'Processing...'
                  : 'Register for Ride'
                : 'Create Account to Register'}
            </button>
          )}
        </div>
      )}
      {registrationMessage && (
        <Message message={registrationMessage} type={registrationMessage.toLowerCase().includes('success') ? 'success' : 'error'} />
      )}
    </div>
  );
}

export default RideRegistration;