import React from 'react';

function RouteTab({ route }) {
  // Extract the route ID from the route link
  const routeIdMatch = route.match(/\/routes\/(\d+)/);
  const routeId = routeIdMatch ? routeIdMatch[1] : '';

  // Construct the correct GPX download link
  const gpxLink = routeId ? `https://ridewithgps.com/routes/${routeId}.gpx?sub_format=track` : '';

  // Construct the embed link
  const embedLink = routeId ? `https://ridewithgps.com/embeds?type=route&id=${routeId}&metricUnits=true&sampleGraph=true&distanceMarkers=true` : '';

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md mt-4">
      <div className="mt-4">
        <p className="font-medium">GPX File:</p>
        {gpxLink ? (
          <a
            href={gpxLink}
            className="text-accent hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        ) : (
          <p className="text-red-500">Invalid route link</p>
        )}
      </div>
      {embedLink && (
        <div className="mt-4">
          <iframe
            src={embedLink}
            style={{ width: '1px', minWidth: '100%', height: '700px', border: 'none' }}
            scrolling="no"
            title="Route Map"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default RouteTab;