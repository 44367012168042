import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ErrorMessage from '../Message';

const fetchRideDetail = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    return response.data;
};

const updateRideDetail = async ({ rideId, updatedDetail, clubId }) => {
    const response = await axios.patch(`/rides/${rideId}`, { details: updatedDetail, clubId });
    return response.data;
};

function RideDetailEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [detail, setDetail] = useState('');
    const [clubId, setClubId] = useState('');
    const [saveError, setSaveError] = useState('');
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);

    const { data: rideDetail, error, isLoading } = useQuery({
        queryKey: ['rideDetail', rideId],
        queryFn: () => fetchRideDetail(rideId),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
    });

    useEffect(() => {
        if (rideDetail) {
            setDetail(rideDetail.details);
            setClubId(rideDetail.clubId);
            if (rideDetail.user) {
                setIsClubOwner(rideDetail.user.isClubOwner);
                setHasAccess(rideDetail.user.isClubOwner);
            }
        }
    }, [rideDetail]);

    const mutation = useMutation({
        mutationFn: updateRideDetail,
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetail', rideId]);
            setSaveError('');
        },
        onError: (error) => {
            if (error.response && error.response.data && error.response.data.message) {
                setSaveError(error.response.data.message);
            } else {
                setSaveError('An unexpected error occurred while updating ride detail.');
            }
            console.error('Error updating ride detail:', error);
        }
    });

    const handleSaveClick = () => {
        if (!isClubOwner) {
            alert('You do not have permission to edit ride details.');
            return;
        }
        mutation.mutate({
            rideId,
            updatedDetail: detail,
            clubId,
        });
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride detail." />;
    if (!hasAccess) return <div className="text-center text-red-500">You don't have access to this page.</div>;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8 space-y-6">
            <h1 className="text-3xl font-bold mb-4">Edit Ride Detail</h1>
            <ReactQuill value={detail} onChange={setDetail} />
            {saveError && <ErrorMessage message={saveError} />}
            {isClubOwner && (
                <button
                    onClick={handleSaveClick}
                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Save Detail
                </button>
            )}
        </div>
    );
}

export default RideDetailEditor;