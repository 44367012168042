import React, { useState } from 'react';

function StartPointComponent({ startPoint, isClubOwner, onSave }) {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(startPoint);

    const handleFieldClick = () => {
        if (isClubOwner) {
            setEditing(true);
        }
    };

    const handleFieldChange = (e) => {
        setValue(e.target.value);
    };

    const handleFieldBlur = async () => {
        await onSave({ field: 'startPoint', value });
        setEditing(false);
    };

    return (
        <div className="bg-background p-4 rounded-lg shadow-md">
            <p className="font-semibold text-textSecondary">Start Point:</p>
            {editing ? (
                <input
                    type="text"
                    value={value}
                    onChange={handleFieldChange}
                    onBlur={handleFieldBlur}
                    className="text-textPrimary border border-gray-300 rounded p-1"
                    autoFocus
                />
            ) : (
                <p
                    className="text-textPrimary cursor-pointer"
                    onClick={handleFieldClick}
                    title={isClubOwner ? 'Click to edit' : ''}
                >
                    {value || 'N/A'}
                </p>
            )}
        </div>
    );
}

export default StartPointComponent;