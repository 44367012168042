import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const RideFormInputs = ({ rideData, handleChange, distanceError, routeError }) => {
    return (
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, backgroundColor: 'white', padding: 2 }}>
            <TextField
                label="Ride Name"
                name="name"
                value={rideData.name}
                onChange={handleChange}
                required
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Start Date Time"
                name="startDateTime"
                type="datetime-local"
                value={rideData.startDateTime}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Registration Closing Date Time"
                name="registrationClosingDateTime"
                type="datetime-local"
                value={rideData.registrationClosingDateTime}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 3600 }} // Only allow round number hours
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Timezone"
                        name="timezone"
                        value={rideData.timezone}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        sx={{ backgroundColor: 'white', width: '100%' }}
                    >
                        <MenuItem value=""><em>Select Timezone</em></MenuItem>
                        <MenuItem value="UTC">UTC</MenuItem>
                        <MenuItem value="GMT">GMT</MenuItem>
                        <MenuItem value="EST">EST</MenuItem>
                        <MenuItem value="CST">CST</MenuItem>
                        <MenuItem value="MST">MST</MenuItem>
                        <MenuItem value="PST">PST</MenuItem>
                        <MenuItem value="IST">IST</MenuItem>
                        <MenuItem value="CET">CET</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <TextField
                label="Start Point"
                name="startPoint"
                value={rideData.startPoint}
                onChange={handleChange}
                required
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Details"
                name="details"
                value={rideData.details}
                onChange={handleChange}
                required
                multiline
                rows={4}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Route (Ride with GPS link)"
                name="route"
                value={rideData.route}
                onChange={handleChange}
                required
                error={!!routeError}
                helperText={routeError}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Distance (km)"
                name="distance"
                type="number"
                value={rideData.distance}
                onChange={handleChange}
                required
                error={!!distanceError}
                helperText={distanceError}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
            <TextField
                label="WhatsApp Group URL"
                name="whatsappGroupUrl"
                value={rideData.whatsappGroupUrl}
                onChange={handleChange}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
            />
        </Box>
    );
};

export default RideFormInputs;
