import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function ControlPointsTab({ controlPoints }) {
  const mapRef = useRef(null);

  const validateCoordinates = (coordinates) => {
    const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
    return !isNaN(lat) && !isNaN(lng);
  };

  const handleClick = (lat, lng) => {
    const map = mapRef.current;
    if (map) {
      map.setView([lat, lng], 15);
    } else {
      console.error('Map instance not found.');
    }
  };

  useEffect(() => {
    if (controlPoints.length === 0 || !validateCoordinates(controlPoints[0].gpsCoordinates)) {
      console.error('No valid control points specified.');
    }
  }, [controlPoints]);

  if (controlPoints.length === 0 || !validateCoordinates(controlPoints[0].gpsCoordinates)) {
    return <p>No valid control points specified.</p>;
  }

  const [initialLat, initialLng] = controlPoints[0].gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));

  return (
    <div className="control-points max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
      <h1 className="text-3xl font-semibold text-textPrimary mb-2">Time Stations</h1>
      <MapContainer
        center={[initialLat, initialLng]}
        zoom={13}
        style={{ height: '400px', width: '100%' }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {controlPoints.map((point, index) => {
          if (!validateCoordinates(point.gpsCoordinates)) {
            console.error(`Invalid coordinates for control point: ${point.description}`);
            return null;
          }
          const [latitude, longitude] = point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
          return (
            <Circle
              key={index}
              center={[latitude, longitude]}
              radius={50} // Adjust the radius as needed
              color="red"
            >
              <Popup>
                <b>{point.description}</b><br />Distance: {point.distance} km
              </Popup>
            </Circle>
          );
        })}
      </MapContainer>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {controlPoints.map((point, index) => {
          const previousDistance = index === 0 ? 0 : controlPoints[index - 1].distance;
          const partialKms = (point.distance - previousDistance).toFixed(1);
          const [latitude, longitude] = point.gpsCoordinates.split(',').map(coord => coord.trim());
          const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
          const closeTime = new Date(point.closeTime);

          return (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-md ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}
              onClick={() => handleClick(parseFloat(latitude), parseFloat(longitude))}
              style={{ cursor: 'pointer' }}
            >
              <h2 className="text-xl font-semibold mb-2 text-textPrimary">
                {`${index + 1}. ${point.description}`}
              </h2>
              <p className="text-textSecondary">Distance: {point.distance} km</p>
              <p className="text-textSecondary">Partial KMs: {partialKms}</p>
              <p className="text-textSecondary">
                Close Time:{' '}
                {point.closeTime ? closeTime.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }) + ', ' + closeTime.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                }) : 'N/A'}
              </p>
              <p className="text-textSecondary">Total Time: {point.totalTime}</p>
              <a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className="text-accent hover:underline">
                View Map
              </a>
              <div className="text-sm text-textSecondary mt-2">
                {point.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ControlPointsTab;