import React, { useState } from 'react';

function ResponsiblePersonComponent({ responsibleName, responsibleEmail, responsiblePhone, whatsappGroupUrl, isClubOwner, onSave }) {
    const [editing, setEditing] = useState({
        responsibleName: false,
        responsibleEmail: false,
        responsiblePhone: false,
        whatsappGroupUrl: false,
    });
    const [values, setValues] = useState({
        responsibleName,
        responsibleEmail,
        responsiblePhone,
        whatsappGroupUrl,
    });

    const handleFieldClick = (field) => {
        if (isClubOwner) {
            setEditing((prevEditing) => ({ ...prevEditing, [field]: true }));
        }
    };

    const handleFieldChange = (field, value) => {
        setValues((prevValues) => ({ ...prevValues, [field]: value }));
    };

    const handleFieldBlur = async (field, value) => {
        await onSave({ field, value });
        setEditing((prevEditing) => ({ ...prevEditing, [field]: false }));
    };

    return (
        <div className="bg-background p-6 rounded-lg shadow-md border border-gray-300 md:col-span-2">
            <h2 className="text-2xl font-semibold text-textPrimary mb-2">Responsible Person</h2>
            <p className="font-semibold text-textSecondary">Name:</p>
            {editing.responsibleName ? (
                <input
                    type="text"
                    value={values.responsibleName}
                    onChange={(e) => handleFieldChange('responsibleName', e.target.value)}
                    onBlur={() => handleFieldBlur('responsibleName', values.responsibleName)}
                    className="text-textPrimary border border-gray-300 rounded p-1 w-full"
                    autoFocus
                />
            ) : (
                <p
                    className="text-textPrimary cursor-pointer"
                    onClick={() => handleFieldClick('responsibleName')}
                    title={isClubOwner ? 'Click to edit' : ''}
                >
                    {values.responsibleName || 'N/A'}
                </p>
            )}
            <p className="font-semibold text-textSecondary mt-2">Email:</p>
            {editing.responsibleEmail ? (
                <input
                    type="email"
                    value={values.responsibleEmail}
                    onChange={(e) => handleFieldChange('responsibleEmail', e.target.value)}
                    onBlur={() => handleFieldBlur('responsibleEmail', values.responsibleEmail)}
                    className="text-textPrimary border border-gray-300 rounded p-1 w-full"
                    autoFocus
                />
            ) : (
                <a
                    href={`mailto:${values.responsibleEmail}`}
                    className="text-accent hover:underline cursor-pointer"
                    onClick={(e) => { if (isClubOwner) { e.preventDefault(); handleFieldClick('responsibleEmail'); } }}
                    title={isClubOwner ? 'Click to edit' : ''}
                >
                    {values.responsibleEmail || 'N/A'}
                </a>
            )}
            <p className="font-semibold text-textSecondary mt-2">Phone:</p>
            {editing.responsiblePhone ? (
                <input
                    type="tel"
                    value={values.responsiblePhone}
                    onChange={(e) => handleFieldChange('responsiblePhone', e.target.value)}
                    onBlur={() => handleFieldBlur('responsiblePhone', values.responsiblePhone)}
                    className="text-textPrimary border border-gray-300 rounded p-1 w-full"
                    autoFocus
                />
            ) : (
                <a
                    href={`tel:${values.responsiblePhone}`}
                    className="text-accent hover:underline cursor-pointer"
                    onClick={(e) => { if (isClubOwner) { e.preventDefault(); handleFieldClick('responsiblePhone'); } }}
                    title={isClubOwner ? 'Click to edit' : ''}
                >
                    {values.responsiblePhone || 'N/A'}
                </a>
            )}
            <p className="font-semibold text-textSecondary mt-2">WhatsApp Group:</p>
            {editing.whatsappGroupUrl ? (
                <input
                    type="url"
                    value={values.whatsappGroupUrl}
                    onChange={(e) => handleFieldChange('whatsappGroupUrl', e.target.value)}
                    onBlur={() => handleFieldBlur('whatsappGroupUrl', values.whatsappGroupUrl)}
                    className="text-textPrimary border border-gray-300 rounded p-1 w-full"
                    autoFocus
                />
            ) : (
                <a
                    href={values.whatsappGroupUrl}
                    className="text-accent hover:underline cursor-pointer"
                    onClick={(e) => { if (isClubOwner) { e.preventDefault(); handleFieldClick('whatsappGroupUrl'); } }}
                    title={isClubOwner ? 'Click to edit' : ''}
                >
                    {values.whatsappGroupUrl ? "Join WhatsApp Group" : 'N/A'}
                </a>
            )}
        </div>
    );
}

export default ResponsiblePersonComponent;