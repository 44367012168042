import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';
import RidersTable from './AdminRidersListComponents/RidersTable';
import ControlPointDetailsPopup from './AdminRidersListComponents/ControlPointDetailsPopup';
import NoCheckInDetailsPopup from './AdminRidersListComponents/NoCheckInDetailsPopup';
import Toast from '../Toast';
import RegisterRiderByURN from './AdminRidersListComponents/RegisterRiderByURN';

// Utility function to safely parse JSON
const safeParseJSON = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

function AdminRidersList() {
    const [riders, setRiders] = useState([]);
    const [controlPoints, setControlPoints] = useState([]);
    const [selectedControl, setSelectedControl] = useState(null);
    const [selectedRider, setSelectedRider] = useState(null);
    const [rideId, setRideId] = useState('');
    const [rideStartTime, setRideStartTime] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [showRegisterRiderForm, setShowRegisterRiderForm] = useState(false);

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const rideId = window.location.pathname.split('/')[2];
                setRideId(rideId);
                const rideDetailsResponse = await axios.get(`/rides/${rideId}`);

                // Get user email from ID token in localStorage
                const idTokenPayload = safeParseJSON(localStorage.getItem('idTokenPayload'));
                const userEmail = idTokenPayload ? idTokenPayload.email : '';

                // Check if the user is the club owner or has the specific email
                const user = rideDetailsResponse.data.user;
                const hasAccess = user.isClubOwner || userEmail === 'rohanshar@gmail.com';
                setHasAccess(hasAccess);

                // If no access, don't proceed
                if (!hasAccess) {
                    return;
                }

                setControlPoints(rideDetailsResponse.data.controlPoints);
                setRideStartTime(rideDetailsResponse.data.startDateTime);

                const response = await axios.get(`/rides/${rideId}/all-checkins`);
                // Ensure that we get the complete payment object, including offlinePayment
                const ridersData = response.data.map(rider => {
                    return {
                        ...rider,
                        payment: rider.payment ? { ...rider.payment } : null,
                    };
                });
                setRiders(ridersData);

            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        fetchAdminData();

        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, []);

    const handleAttachOfflinePayment = async (sub) => {
        try {
            await axios.post(`/riders/${sub}/rides/${rideId}/attach-offline-payment`, {});
            setRiders((prevRiders) =>
                prevRiders.map((rider) =>
                    rider.sub === sub ? { ...rider, payment: { status: 'paid', offlinePayment: true } } : rider
                )
            );
            showToast('Payment updated successfully!');
        } catch (error) {
            console.error('Error attaching offline payment:', error);
            showToast('Error attaching offline payment.');
        }
    };

    const handleControlClick = (checkedIn, point, rider, idx) => {
        setSelectedRider(rider);

        const matchedControl = controlPoints[idx];

        if (matchedControl) {
            const riderControl = rider.ControlPoints.find(cp =>
                cp.ControlPointName === matchedControl.description && cp.ControlPointNumber === idx + 1
            );

            if (riderControl) {
                setSelectedControl({ ...riderControl, closeTime: matchedControl.closeTime });
            } else {
                setSelectedControl({
                    controlPointNumber: (idx + 1).toString(),
                    controlPointName: matchedControl.description
                });
            }
        }
    };

    const handleStatusChange = async (sub, newStatus, totalTime) => {
        try {
            const payload = { result: newStatus };
            if (totalTime) {
                payload.totalTime = totalTime;
            }
            await axios.post(`/riders/${sub}/rides/${rideId}/status`, payload);
            setRiders((prevRiders) =>
                prevRiders.map((rider) =>
                    rider.sub === sub ? { ...rider, Result: newStatus, TotalTime: totalTime || rider.TotalTime } : rider
                )
            );
        } catch (error) {
            console.error('Error updating rider status:', error);
        }
    };

    const handleClearResult = async (sub) => {
        try {
            const payload = { result: '', totalTime: '' };
            await axios.post(`/riders/${sub}/rides/${rideId}/status`, payload);
            setRiders((prevRiders) =>
                prevRiders.map((rider) =>
                    rider.sub === sub ? { ...rider, Result: '', TotalTime: '' } : rider
                )
            );
        } catch (error) {
            console.error('Error clearing rider result:', error);
        }
    };

    const handleClosePopup = () => {
        setSelectedControl(null);
        setSelectedRider(null);
    };

    const showToast = (message) => {
        setToastMessage(message);
        setTimeout(() => setToastMessage(''), 3000);
    };

    const handleRegisterRiderSuccess = (newRider) => {
        setRiders((prevRiders) => [...prevRiders, newRider]);
        showToast('Rider registered successfully!');
    };

    if (!hasAccess) {
        return <div className='text-center text-red-500'>You don't have access to this page.</div>;
    }

    return (
        <div className='overflow-x-auto'>
            <h2 className='text-2xl font-semibold text-primary mb-4'>All Registered Riders</h2>
            {hasAccess && (
                <button
                    onClick={() => setShowRegisterRiderForm(true)}
                    className='mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                >
                    Register Rider
                </button>
            )}
            <RidersTable
                riders={riders}
                setRiders={setRiders}
                controlPoints={controlPoints}
                handleControlClick={handleControlClick}
                handleStatusChange={handleStatusChange}
                handleClearResult={handleClearResult}
                showToast={showToast}
                handleAttachOfflinePayment={handleAttachOfflinePayment}
            />
            {selectedControl && (
                selectedControl.closeTime ? (
                    <ControlPointDetailsPopup
                        controlDetails={selectedControl}
                        onClose={handleClosePopup}
                        rideStartTime={rideStartTime}
                    />
                ) : (
                    <NoCheckInDetailsPopup
                        onClose={handleClosePopup}
                        rideId={rideId}
                        controlPointName={selectedControl ? selectedControl.controlPointName : ''}
                        controlPointNumber={selectedControl ? selectedControl.controlPointNumber : ''}
                        rider={selectedRider}
                    />
                )
            )}
            {showRegisterRiderForm && (
                <RegisterRiderByURN
                    rideId={rideId}
                    onClose={() => setShowRegisterRiderForm(false)}
                    onSuccess={handleRegisterRiderSuccess}
                />
            )}
            {toastMessage && <Toast message={toastMessage} />}
        </div>
    );
}

export default AdminRidersList;
