import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaUserCircle, FaBicycle, FaCalendar, FaUsers, FaComments, FaDownload } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import PDFDownloadButton from './Profile/PDFDownloadButton';

function Dashboard() {
    const navigate = useNavigate();
    const { state, logout } = useAuth();
    const { user, idTokenPayload } = state;

    const urn = idTokenPayload?.['custom:userId'];
    const name = idTokenPayload?.name;

    console.log({ state });

    const handleProfileClick = () => {
        navigate('/profile', { state: { name: user?.email, urn } });
    };

    const handlePublicProfileClick = () => {
        navigate(`/athletes/${urn}/profile`);
    };

    return (
        <div className="min-h-screen bg-lightGray p-4">
            <div className="max-w-6xl mx-auto p-6 bg-lightContainer rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-center text-textPrimary mb-6">Dashboard</h1>
                <p className="text-lg text-center text-textSecondary mb-4">Welcome to your dashboard. Manage your profile and explore rides.</p>
                {name && (
                    <p className="text-lg text-center text-textSecondary mb-6">Hi <span className="font-bold">{name}</span></p>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200 text-center">
                        <FaUserCircle className="text-5xl text-primary mb-4 mx-auto" />
                        <h2 className="text-xl font-semibold text-textPrimary mb-2">Profile</h2>
                        <p className="text-textSecondary mb-4">Manage your profile information and settings.</p>
                        <p className="text-lg text-center text-textSecondary mb-4">URN: <span className="font-bold">{urn}</span></p>
                        <div className="flex flex-col justify-center space-y-4">
                            <button
                                onClick={handleProfileClick}
                                className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors"
                            >
                                Edit
                            </button>
                            <button
                                onClick={handlePublicProfileClick}
                                className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors"
                            >
                                View Profile
                            </button>
                            <PDFDownloadButton
                                leftSideData={{
                                    customUserId: urn || '',
                                    name: name || ''
                                }}
                                idTokenPayload={idTokenPayload}
                            >
                                <FaDownload className="mr-2" />
                                Download Rider Card
                            </PDFDownloadButton>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200 text-center">
                        <FaBicycle className="text-5xl text-primary mb-4 mx-auto" />
                        <h2 className="text-xl font-semibold text-textPrimary mb-2">My Rides</h2>
                        <p className="text-textSecondary mb-4">Check your upcoming and past rides.</p>
                        <div className="flex flex-col space-y-4">
                            <button
                                onClick={() => navigate('/myrides')}
                                className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors"
                            >
                                View My Rides
                            </button>
                            <button
                                onClick={() => navigate('/mychats')}
                                className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors flex items-center justify-center"
                            >
                                <FaComments className="mr-2" /> Ride Chats
                            </button>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200 text-center">
                        <FaCalendar className="text-5xl text-primary mb-4 mx-auto" />
                        <h2 className="text-xl font-semibold text-textPrimary mb-2">Rides Calendar</h2>
                        <p className="text-textSecondary mb-4">View all past and upcoming rides.</p>
                        <button
                            onClick={() => navigate('/rides')}
                            className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors"
                        >
                            View Calendar
                        </button>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg border border-primary transition-shadow duration-200 text-center">
                        <FaUsers className="text-5xl text-primary mb-4 mx-auto" />
                        <h2 className="text-xl font-semibold text-textPrimary mb-2">Owner Dashboard</h2>
                        <p className="text-textSecondary mb-4">Create and manage your own club.</p>
                        <button
                            onClick={() => navigate('/owner-dashboard')}
                            className="border border-primary text-primary py-2 px-6 rounded-full hover:bg-primary hover:text-white transition-colors"
                        >
                            Go to Owner Dashboard
                        </button>
                    </div>
                </div>
                <div className="text-center mt-8">
                    <button
                        onClick={logout}
                        className="border border-error text-error py-3 px-8 rounded-full hover:bg-error hover:text-white transition-colors flex items-center justify-center mx-auto"
                    >
                        <FaSignOutAlt className="mr-2" />
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
