import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import userPool from '../config/cognitoConfig';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import axios from '../config/axiosConfig';

function UserRegistration() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [message, setMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return 'Password must be at least 8 characters long';
    }
    if (!hasUpperCase) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!hasLowerCase) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!hasNumber) {
      return 'Password must contain at least one digit';
    }
    if (!hasSpecialChar) {
      return 'Password must contain at least one special character';
    }

    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'password') {
      const error = validatePassword(value);
      setPasswordError(error);
    }
  };

  const handleVerificationChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (formData.password !== formData.confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    if (passwordError) {
      setMessage(passwordError);
      return;
    }

    if (!formData.email || !formData.password || !formData.name) {
      setMessage('Please fill in all required fields');
      return;
    }

    try {
      setLoading(true);

      const attributeList = [];
      attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: formData.email }));
      attributeList.push(new CognitoUserAttribute({ Name: 'name', Value: formData.name }));

      userPool.signUp(formData.email, formData.password, attributeList, null, async (err, result) => {
        if (err) {
          setMessage(`Registration error: ${err.message}`);
          console.error('Registration error:', err);
          setLoading(false);
          return;
        }
        setMessage('Registration successful! Please check your email for verification.');
        setShowVerification(true);
        setLoading(false);

        // Automatically join the user to a default ride after registration
        try {
          const joinResponse = await axios.post(`/rides/default/join`, { email: formData.email });
          if (joinResponse.status === 200) {
            console.log('Successfully joined the default ride');
          } else {
            console.error('Failed to join the default ride');
          }
        } catch (joinError) {
          console.error('Error joining default ride:', joinError);
        }
      });

    } catch (error) {
      setLoading(false);
      setMessage(`Registration error: ${error.message}`);
      console.error('Registration error:', error);
    }
  };

  const handleVerificationSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const cognitoUser = new CognitoUser({ Username: formData.email, Pool: userPool });

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        console.error('Verification error:', err);
        setMessage(`Verification error: ${err.message}`);
        setLoading(false);
        return;
      }
      setMessage('Verification successful! You can now log in.');
      setShowVerification(false);
      setLoading(false);

      // Redirect user to the original destination after verification
      const redirectUrl = new URLSearchParams(location.search).get('redirect');
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate('/dashboard');
      }
    });
  };

  const queryParams = location.search;

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg mt-8">
      <h1 className="text-2xl font-semibold text-primary mb-4">Create a New Account</h1>
      {message && <p className={message.includes('error') ? 'text-red-500' : 'text-green-500'}>{message}</p>}
      {!showVerification ? (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Create Account'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerificationSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={handleVerificationChange}
            required
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      )}
      <p className="text-primary mt-4">
        Already have an account? <Link to={`/login${queryParams}`} className="hover:text-secondary transition-all">Login here</Link>.
      </p>
    </div>
  );
}

export default UserRegistration;
