import React, { useState, useEffect } from 'react';
import RiderRow from './RiderRow';

function RidersTable({ riders, setRiders, controlPoints, handleControlClick, handleStatusChange, handleClearResult, showToast, handleAttachOfflinePayment }) {
    const [sortOrder, setSortOrder] = useState('asc');
    const [totalPayments, setTotalPayments] = useState(0);
    const [totalWaivers, setTotalWaivers] = useState(0);
    const [paymentSortOrder, setPaymentSortOrder] = useState('asc');
    const [waiverSortOrder, setWaiverSortOrder] = useState('asc');
    const [resultSortOrder, setResultSortOrder] = useState('asc');

    useEffect(() => {
        const paymentCount = riders.filter(rider => rider.payment).length;
        const waiverCount = riders.filter(rider => rider.waiver).length;
        setTotalPayments(paymentCount);
        setTotalWaivers(waiverCount);
    }, [riders]);

    const sortBy = (key, sortOrderState, setSortOrderState) => {
        const sorted = [...riders].sort((a, b) => {
            const valA = a[key] ? 1 : 0;
            const valB = b[key] ? 1 : 0;
            if (sortOrderState === 'asc') {
                return valA - valB;
            } else {
                return valB - valA;
            }
        });
        setRiders(sorted);
        setSortOrderState(sortOrderState === 'asc' ? 'desc' : 'asc');
    };

    const sortByURN = () => {
        const sorted = [...riders].sort((a, b) => {
            const urnA = parseInt(a.URN, 10);
            const urnB = parseInt(b.URN, 10);
            if (sortOrder === 'asc') {
                return urnA - urnB;
            } else {
                return urnB - urnA;
            }
        });
        setRiders(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const sortByResult = () => {
        const sorted = [...riders].sort((a, b) => {
            if (resultSortOrder === 'asc') {
                return (a.Result || '').localeCompare(b.Result || '');
            } else {
                return (b.Result || '').localeCompare(a.Result || '');
            }
        });
        setRiders(sorted);
        setResultSortOrder(resultSortOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <table className='min-w-full bg-white border rounded-lg overflow-hidden shadow-lg'>
            <thead className='bg-primary text-white sticky top-0'>
                <tr>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>#</th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left cursor-pointer' onClick={sortByURN} title='Click to sort by URN'>
                        URN <span className='text-xs'>&#x25B2;&#x25BC;</span>
                    </th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Name</th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Email</th>
                    {controlPoints.map((point, index) => (
                        <th key={index} className='py-2 px-4 border-b-2 border-primary text-left'>TS{index + 1}</th>
                    ))}
                    <th className='py-2 px-4 border-b-2 border-primary text-left cursor-pointer' onClick={() => sortBy('payment', paymentSortOrder, setPaymentSortOrder)}>
                        Payment <span className='text-xs'>&#x25B2;&#x25BC;</span> ({totalPayments})
                    </th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left cursor-pointer' onClick={() => sortBy('waiver', waiverSortOrder, setWaiverSortOrder)}>
                        Waiver <span className='text-xs'>&#x25B2;&#x25BC;</span> ({totalWaivers})
                    </th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left cursor-pointer' onClick={sortByResult}>
                        Result <span className='text-xs'>&#x25B2;&#x25BC;</span>
                    </th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Time</th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Provisional Result</th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Edit Result</th>
                    <th className='py-2 px-4 border-b-2 border-primary text-left'>Clear</th>
                </tr>
            </thead>
            <tbody>
                {riders.map((user, index) => (
                    <RiderRow
                        key={index}
                        user={user}
                        controlPoints={controlPoints}
                        handleControlClick={handleControlClick}
                        handleStatusChange={handleStatusChange}
                        handleClearResult={handleClearResult}
                        index={index}
                        showToast={showToast}
                        handleAttachOfflinePayment={handleAttachOfflinePayment}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default RidersTable;
