import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';

const fetchRideDetails = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    return response.data;
};

const updateRideDetails = async ({ rideId, updatedData }) => {
    const response = await axios.patch(`/rides/${rideId}`, updatedData);
    return response.data;
};

function RideDetails() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();

    const [editMode, setEditMode] = useState(false);
    const [registrationClosingDateTime, setRegistrationClosingDateTime] = useState('');
    const [saveError, setSaveError] = useState('');
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);

    const { data: ride, error, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => fetchRideDetails(rideId),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
    });

    useEffect(() => {
        if (ride?.user) {
            setIsClubOwner(ride.user.isClubOwner);
            setHasAccess(ride.user.isClubOwner);
        }
    }, [ride]);

    const mutation = useMutation({
        mutationFn: updateRideDetails,
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setEditMode(false);
            setSaveError('');
        },
        onError: (error) => {
            if (error.response && error.response.data && error.response.data.message) {
                setSaveError(error.response.data.message);
            } else {
                setSaveError('An unexpected error occurred while updating ride details.');
            }
            console.error('Error updating ride details:', error);
        }
    });

    const handleEditClick = () => {
        if (!isClubOwner) {
            alert('You do not have permission to edit ride details.');
            return;
        }
        setRegistrationClosingDateTime(ride.registrationClosingDateTime);
        setEditMode(true);
    };

    const handleSaveClick = () => {
        if (!isClubOwner) {
            alert('You do not have permission to save ride details.');
            return;
        }
        mutation.mutate({
            rideId,
            updatedData: {
                registrationClosingDateTime,
                clubId: ride?.clubId,
            },
        });
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride details." />;
    if (!hasAccess) return <div className="text-center text-red-500">You don't have access to this page.</div>;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8 space-y-6">
            <h1 className="text-3xl font-bold mb-4">Ride Information</h1>
            <p className="text-xl"><strong>Ride Name:</strong> {ride.name}</p>
            <p className="text-xl"><strong>Start Time:</strong> {new Date(ride.startDateTime).toLocaleString()}</p>
            <p className="text-xl"><strong>Club Name:</strong> {ride.clubName}</p>
            <p className="text-xl"><strong>Distance:</strong> {ride.distance} km</p>
            <p className="text-xl"><strong>Number of Control Points:</strong> {ride.controlPoints.length}</p>
            <ul className="list-disc pl-6">
                {ride.controlPoints.map((controlPoint, index) => (
                    <li key={index} className="text-xl">
                        Control Point {index + 1}: {controlPoint.description} (Close Time: {new Date(controlPoint.closeTime).toLocaleString()})
                    </li>
                ))}
            </ul>
            <div className="text-xl">
                <strong>Registration Closing Time:</strong>
                {editMode ? (
                    <input
                        type="datetime-local"
                        value={registrationClosingDateTime}
                        onChange={(e) => setRegistrationClosingDateTime(e.target.value)}
                        className="border border-gray-300 rounded p-2 ml-2"
                    />
                ) : (
                    <span> {new Date(ride.registrationClosingDateTime).toLocaleString()}</span>
                )}
            </div>
            {saveError && <ErrorMessage message={saveError} />}
            {isClubOwner && (
                editMode ? (
                    <button
                        onClick={handleSaveClick}
                        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Save
                    </button>
                ) : (
                    <button
                        onClick={handleEditClick}
                        className="mt-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Edit Registration Closing Time
                    </button>
                )
            )}
        </div>
    );
}

export default RideDetails;