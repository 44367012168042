import React from 'react';

const TotalUltraRidesDistance = ({ rideHistory }) => {
  const totalKms = rideHistory.reduce((total, ride) => {
    if (ride.Result === 'Finished') {
      return total + ride.rideDistance;
    }
    return total;
  }, 0);

  const ridesWithResult = rideHistory.filter(ride => ride.Result === 'Finished');
  const totalRides = ridesWithResult.length;
  const longestRide = totalRides > 0 ? Math.max(...ridesWithResult.map(ride => ride.rideDistance)) : 0;

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center mb-4">
          <p className="text-5xl font-bold text-accent">{totalKms}</p>
          <p className="text-sm text-textSecondary">Total Kilometers</p>
        </div>
        <div className="flex flex-col items-center mb-4">
          <p className="text-5xl font-bold text-accent">{totalRides}</p>
          <p className="text-sm text-textSecondary">Total Rides</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-5xl font-bold text-accent">{longestRide}</p>
          <p className="text-sm text-textSecondary">Longest Ride (km)</p>
        </div>
      </div>
    </div>
  );
};

export default TotalUltraRidesDistance;