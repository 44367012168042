import React from 'react';
import ControlPointCard from './ControlPointCard';
import { FaPlusCircle } from 'react-icons/fa';

const ControlPointsList = ({ controlPoints, handleInputChange, handleAddControlPoint, handleRemoveControlPoint, validationErrors, rideStartTime }) => {
    return (
        <div>
            {controlPoints.map((controlPoint, index) => (
                <div key={index}>
                    <ControlPointCard
                        controlPoint={controlPoint}
                        index={index}
                        handleInputChange={handleInputChange}
                        handleRemoveControlPoint={handleRemoveControlPoint}
                        validationErrors={validationErrors}
                        rideStartTime={rideStartTime} // Pass rideStartTime here
                    />
                    <div className="flex justify-center mb-6">
                        <button
                            onClick={() => handleAddControlPoint(index)}
                            className="text-green-500 hover:text-green-700 text-3xl"
                            title="Add Control Point Below"
                        >
                            <FaPlusCircle />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ControlPointsList;
