import React from 'react';
import { FaTrash } from 'react-icons/fa';

function ControlPointCard({ controlPoint, index, handleInputChange, handleRemoveControlPoint, validationErrors, rideStartTime }) {
    const calculateTimeIntoRide = (startTime, closeTime) => {
        const start = new Date(startTime);
        const close = new Date(closeTime);
        const duration = close - start; // in milliseconds
        const hours = Math.floor(duration / 3600000);
        const minutes = Math.floor((duration % 3600000) / 60000);
        return `${hours}h ${minutes}m`;
    };

    const timeIntoRide = calculateTimeIntoRide(rideStartTime, controlPoint.closeTime);

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
            <h2 className="text-2xl font-semibold mb-4">Control Point {index + 1}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Description
                    </label>
                    <input
                        type="text"
                        value={controlPoint.description}
                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        disabled={!controlPoint.editable} // Disable input if not editable (first control)
                    />
                </div>
                {index !== 0 && (
                    <>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Total Distance (km)
                            </label>
                            <input
                                type="number"
                                value={controlPoint.distance}
                                onChange={(e) => handleInputChange(index, 'distance', e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="e.g., 15.5"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Close Time
                            </label>
                            <input
                                type="datetime-local"
                                value={controlPoint.closeTime}
                                onChange={(e) => handleInputChange(index, 'closeTime', e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                disabled // Make close time not editable
                            />
                        </div>
                        <div className="col-span-2 text-gray-600 text-sm">
                            <p>Total Time into Ride: {timeIntoRide}</p>
                        </div>
                    </>
                )}
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Latitude
                    </label>
                    <input
                        type="number"
                        step="0.00001"
                        value={controlPoint.latitude}
                        onChange={(e) => handleInputChange(index, 'latitude', parseFloat(e.target.value))}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Latitude"
                    />
                    {validationErrors[`latitude_${index}`] && <p className="text-red-500 text-xs mt-1">{validationErrors[`latitude_${index}`]}</p>}
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Longitude
                    </label>
                    <input
                        type="number"
                        step="0.00001"
                        value={controlPoint.longitude}
                        onChange={(e) => handleInputChange(index, 'longitude', parseFloat(e.target.value))}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Longitude"
                    />
                    {validationErrors[`longitude_${index}`] && <p className="text-red-500 text-xs mt-1">{validationErrors[`longitude_${index}`]}</p>}
                </div>
            </div>
            <div className="flex justify-end mt-4">
                <button
                    onClick={() => handleRemoveControlPoint(index)}
                    className="text-red-500 hover:text-red-700 text-lg"
                    title="Remove Control Point"
                >
                    <FaTrash />
                </button>
            </div>
        </div>
    );
}

export default ControlPointCard;
