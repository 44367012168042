import React, { useState } from 'react';

const GPSInput = ({ value, onChange }) => {
    const [error, setError] = useState('');

    const validateCoordinates = (coordinates) => {
        const regex = /^-?\d{1,2}\.\d{4,},\s*-?\d{1,3}\.\d{4,}$/;
        return regex.test(coordinates);
    };

    const roundToFourDecimals = (num) => {
        return Math.round(num * 10000) / 10000;
    };

    const handleBlur = () => {
        const coordinateParts = value.split(',').map(part => part.trim());
        if (coordinateParts.length !== 2) {
            setError('Invalid GPS coordinates. Format: latitude, longitude (e.g., 12.3456, -123.4567)');
        } else {
            let [latitude, longitude] = coordinateParts;
            latitude = parseFloat(latitude);
            longitude = parseFloat(longitude);

            if (isNaN(latitude) || isNaN(longitude)) {
                setError('Invalid GPS coordinates. Format: latitude, longitude (e.g., 12.3456, -123.4567)');
            } else {
                if (latitude.toFixed(4) !== latitude.toString() || longitude.toFixed(4) !== longitude.toString()) {
                    latitude = roundToFourDecimals(latitude);
                    longitude = roundToFourDecimals(longitude);
                    onChange(`${latitude}, ${longitude}`);
                }
                if (!validateCoordinates(`${latitude}, ${longitude}`)) {
                    setError('Coordinates must have at least 4 decimal places.');
                } else {
                    setError('');
                }
            }
        }
    };

    const handleChange = (value) => {
        onChange(value);
        const coordinateParts = value.split(',').map(part => part.trim());
        if (coordinateParts.length !== 2) {
            setError('Invalid GPS coordinates. Format: latitude, longitude (e.g., 12.3456, -123.4567)');
        } else {
            const [latitude, longitude] = coordinateParts;
            const decimalRegex = /^\-?\d+\.\d{4,}$/;
            if (!decimalRegex.test(latitude) || !decimalRegex.test(longitude)) {
                setError('Coordinates must have at least 4 decimal places.');
            } else if (!validateCoordinates(value)) {
                setError('Invalid GPS coordinates. Format: latitude, longitude (e.g., 12.3456, -123.4567)');
            } else {
                setError('');
            }
        }
    };

    return (
        <div className="flex flex-col mb-2">
            <label className="mb-1">GPS Coordinates:</label>
            <input
                type="text"
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur} // Validate and round on blur
                className={`p-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded`}
                placeholder="latitude, longitude"
            />
            {error && <span className="text-red-500 mt-1">{error}</span>}
        </div>
    );
};

export default GPSInput;