export const calculateControlTimes = (rideDistance, controlDistance, timeControlType, startDateTime) => {
  console.log({ rideDistance, controlDistance, timeControlType, startDateTime });

  const startDateTimeObj = new Date(startDateTime);
  if (isNaN(startDateTimeObj.getTime())) {
    return ['Invalid time', 'Invalid time'];
  }

  const minSpeed = timeControlType === 'BRM' ? 15 : 12.5;
  const controlTimeInMs = (controlDistance / minSpeed) * 3600000;
  const closeTime = new Date(startDateTimeObj.getTime() + controlTimeInMs);

  const formatTime = (time) => {
    if (isNaN(time.getTime())) {
      return 'Invalid time';
    }
    const year = time.getFullYear();
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const day = time.getDate().toString().padStart(2, '0');
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatTotalTime = (timeInMs) => {
    const hours = Math.floor(timeInMs / 3600000);
    const minutes = Math.floor((timeInMs % 3600000) / 60000);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const totalTime = formatTotalTime(controlTimeInMs);
  console.log(formatTime(closeTime));

  return [formatTime(closeTime), totalTime];
};