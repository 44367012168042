import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import TimingControls from './NewRide/TimingControls';
import { useAuth } from '../context/AuthContext';
import RideFormInputs from './NewRide/RideFormInputs';
import TimingControlCheckbox from './NewRide/TimingControlCheckbox';
import ResponsiblePersonInputs from './NewRide/ResponsiblePersonInputs';

function NewRide() {
  const { state } = useAuth();
  const [rideData, setRideData] = useState({
    name: '',
    date: '',
    startPoint: '',
    time: '',
    details: '',
    route: '',
    distance: '',
    isTimeControlled: false,
    timeControlType: '',
    controlPoints: [],
    minimumControlPoints: 0,
    responsibleName: '',
    responsibleEmail: '',
    responsiblePhone: '',
    rulesAndRegulations: '',
    routeDetails: '',
    whatsappGroupUrl: '' // Added field
  });
  const [message, setMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState('');
  const [stackTrace, setStackTrace] = useState('');
  const [distanceError, setDistanceError] = useState('');
  const [routeError, setRouteError] = useState('');
  const [loading, setLoading] = useState(false);
  const { clubId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    validateDistance(rideData.distance);
    validateRoute(rideData.route);
    if (rideData.distance && !distanceError) {
      prefillControlPoints(rideData.distance);
    }
  }, [rideData.distance, rideData.route]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    if (name === 'isTimeControlled' && !checked) {
      setRideData({
        ...rideData,
        [name]: val,
        timeControlType: '',
        controlPoints: [],
        minimumControlPoints: 0
      });
    } else {
      setRideData({ ...rideData, [name]: val });
    }
  };

  const handleControlChange = (index, field, value) => {
    const updatedControls = rideData.controlPoints.map((control, i) => (
      i === index ? { ...control, [field]: value } : control
    ));
    setRideData({ ...rideData, controlPoints: updatedControls });
  };

  const validateDistance = (distance) => {
    const dist = parseInt(distance, 10);
    if (isNaN(dist)) {
      setDistanceError('Distance must be a number.');
      return false;
    }
    if (dist < 100 || dist > 2000) {
      setDistanceError('Distance must be between 100 and 2000.');
      return false;
    }
    if (dist === 160) {
      setDistanceError(''); // Clears any previous error for this special case
      return true;          // Allows 160 specifically
    }
    if (dist <= 250 && dist % 50 !== 0) {
      setDistanceError('Distance must be a multiple of 50 up to 250.');
      return false;
    }
    if (dist > 250 && dist % 100 !== 0 && dist !== 160) { // Check again, though it will always pass for 160
      setDistanceError('Distance must be a multiple of 100 above 250, except for 160.');
      return false;
    }
    setDistanceError('');
    return true;
  };

  const validateRoute = (route) => {
    const regex = /^https:\/\/ridewithgps\.com\/routes\/[0-9]+$/;
    if (!regex.test(route)) {
      setRouteError('Route must be a valid Ride with GPS link.');
      return false;
    }
    setRouteError('');
    return true;
  };

  const prefillControlPoints = (distance) => {
    let minimumControlPoints = Math.floor(distance / 100);
    if (distance > 500) {
      minimumControlPoints = 5 + Math.floor((distance - 500) / 200);
    }
    const controlPoints = Array.from({ length: minimumControlPoints }, (_, i) => ({
      distance: (i + 1) * (distance <= 500 ? 100 : (i < 5 ? 100 : 200)),
      openTime: '',
      closeTime: '',
      description: ''
    }));
    setRideData({ ...rideData, controlPoints, minimumControlPoints });
  };

  const handleControlPointsChange = (e) => {
    const { value } = e.target;
    const count = parseInt(value, 10);
    if (isNaN(count) || count < rideData.minimumControlPoints) return;

    const newControlPoints = Array.from({ length: count }, (_, i) => (
      rideData.controlPoints[i] || {
        distance: '',
        openTime: '',
        closeTime: '',
        description: ''
      }
    ));
    setRideData({ ...rideData, controlPoints: newControlPoints });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrorDetails('');
    setStackTrace('');
    setLoading(true);

    if (!validateDistance(rideData.distance) || !validateRoute(rideData.route)) {
      setMessage('Please correct the errors before submitting.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`/clubs/${clubId}/rides`, { ...rideData, clubId, userId: state.userId });

      if (response.status === 200 || response.status === 201) {
        setMessage('Ride created successfully!');
        navigate('/owner-dashboard');
      } else {
        setMessage('Failed to create ride. Please try again.');
      }
    } catch (error) {
      console.error('Error creating ride:', error);
      setMessage('Error creating ride. Please contact support.');
      setErrorDetails(error.response?.data?.error || 'Unknown error occurred.');
      setStackTrace(error.response?.data?.stack || '');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-background-light rounded-lg shadow-lg mt-8">
      <h1 className="text-2xl font-semibold text-primary mb-4">Create New Ride</h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <RideFormInputs
          rideData={rideData}
          handleChange={handleChange}
          distanceError={distanceError}
          routeError={routeError}
        />
        <ResponsiblePersonInputs rideData={rideData} handleChange={handleChange} />
        <TimingControlCheckbox rideData={rideData} handleChange={handleChange} />
        {rideData.isTimeControlled && rideData.distance && !distanceError && (
          <TimingControls
            rideData={rideData}
            handleChange={handleChange}
            handleControlChange={handleControlChange}
            handleControlPointsChange={handleControlPointsChange}
          />
        )}
        <textarea
          name="rulesAndRegulations"
          value={rideData.rulesAndRegulations}
          onChange={handleChange}
          placeholder="Rules and Regulations"
          rows={4}
          className="p-2 border border-gray-300 rounded"
        />
        <textarea
          name="routeDetails"
          value={rideData.routeDetails}
          onChange={handleChange}
          placeholder="Route Details"
          rows={4}
          className="p-2 border border-gray-300 rounded"
        />
        <button
          type="submit"
          className="bg-primary text-white py-2 rounded hover:bg-primary-dark transition-all"
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Ride'}
        </button>
        {(distanceError || routeError || message || errorDetails) && (
          <div className="text-red-500 mt-4">
            {distanceError && <p>{distanceError}</p>}
            {routeError && <p>{routeError}</p>}
            {message && <p>{message}</p>}
            {errorDetails && (
              <div>
                <p><strong>Error Details:</strong> {errorDetails}</p>
              </div>
            )}
            {stackTrace && (
              <div>
                <p><strong>Stack Trace:</strong></p>
                <pre className="whitespace-pre-wrap">{stackTrace}</pre>
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default NewRide;
