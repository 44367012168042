import React from 'react';

const ResponsiblePersonInputs = ({ rideData, handleChange }) => {
    return (
        <>
            <input
                type="text"
                name="responsibleName"
                placeholder="Responsible Person's Name"
                value={rideData.responsibleName}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
            />
            <input
                type="email"
                name="responsibleEmail"
                placeholder="Responsible Person's Email"
                value={rideData.responsibleEmail}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
            />
            <input
                type="tel"
                name="responsiblePhone"
                placeholder="Responsible Person's Phone Number"
                value={rideData.responsiblePhone}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
            />
        </>
    );
};

export default ResponsiblePersonInputs;
