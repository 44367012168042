import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';

const fetchRideDetails = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    return response.data;
};

function RidePayment() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [paymentAmount, setPaymentAmount] = useState('');
    const [currency, setCurrency] = useState('INR');
    const [isEditing, setIsEditing] = useState(false);
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const [platformFee, setPlatformFee] = useState(0);

    const { data: ride, error, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => fetchRideDetails(rideId),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
    });

    useEffect(() => {
        if (ride?.name) {
            document.title = `${ride.name} - Set Payment | Ultra Rides`;
        }
        if (ride?.payment) {
            setPaymentAmount(ride.payment.amount);
            setCurrency(ride.payment.currency || 'INR');
        }
        if (ride?.user) {
            setIsClubOwner(ride.user.isClubOwner);
            setHasAccess(ride.user.isClubOwner);
        }
    }, [ride]);

    useEffect(() => {
        calculatePlatformFee(paymentAmount);
    }, [paymentAmount]);

    const calculatePlatformFee = (amount) => {
        const fee = Math.min(amount * 0.075, 100);
        setPlatformFee(fee);
    };

    const handleSetPayment = async () => {
        if (!isClubOwner) {
            alert('You do not have permission to set payment.');
            return;
        }

        if (!paymentAmount || paymentAmount <= 0) {
            alert('Please enter a valid payment amount.');
            return;
        }

        if (!ride?.clubId) {
            alert('Club ID is missing');
            return;
        }

        try {
            await axios.patch(`/rides/${rideId}`, {
                payment: {
                    amount: paymentAmount,
                    currency: currency,
                },
                clubId: ride.clubId,
            });
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setIsEditing(false);
        } catch (error) {
            console.error('Error setting payment:', error);
            alert('Error setting payment');
        }
    };

    const handleRemovePayment = async () => {
        if (!isClubOwner) {
            alert('You do not have permission to remove payment.');
            return;
        }

        if (!ride?.clubId) {
            alert('Club ID is missing');
            return;
        }

        try {
            await axios.patch(`/rides/${rideId}`, {
                payment: null,
                clubId: ride.clubId,
            });
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setIsEditing(false);
        } catch (error) {
            console.error('Error removing payment:', error);
            alert('Error removing payment');
        }
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride details." />;
    if (!hasAccess) return <div className="text-center text-red-500">You don't have access to this page.</div>;

    if (!ride?.club?.razorpayId) {
        return (
            <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8 space-y-6">
                <h1 className="text-3xl font-bold mb-4">Set Ride Payment</h1>
                <p className="text-xl">Ride Name: {ride?.name}</p>
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-4" role="alert">
                    <p className="font-bold">Payment Setup Required</p>
                    <p>This ride doesn't have payment details attached. To enable payments:</p>
                    <ol className="list-decimal list-inside mt-2">
                        <li>Go to your club dashboard</li>
                        <li>Locate the payment settings section</li>
                        <li>Connect your Razorpay account</li>
                        <li>Now your future rides will have payment enabled</li>
                        <li>To add payment to this ride contact support</li>
                    </ol>
                    <p className="mt-2">Once you've completed these steps, you'll be able to set up payments for your rides.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8 space-y-6">
            <h1 className="text-3xl font-bold mb-4">Set Ride Payment</h1>
            <p className="text-xl">Ride Name: {ride.name}</p>
            {ride.payment && !isEditing ? (
                <div>
                    <p className="text-xl">Ride is currently priced at {ride.payment.amount} {ride.payment.currency} per user.</p>
                    {isClubOwner && (
                        <>
                            <button
                                onClick={() => setIsEditing(true)}
                                className="mt-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Edit Payment
                            </button>
                            <button
                                onClick={handleRemovePayment}
                                className="mt-4 ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Remove Payment (Make it Free)
                            </button>
                        </>
                    )}
                </div>
            ) : ride.payment === null ? (
                <div>
                    <p className="text-xl">This is currently a free ride.</p>
                    {isClubOwner && (
                        <>
                            <p className="text-md text-gray-700">You can make this a paid ride by adding a payment amount below.</p>
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentAmount">
                                    Payment Amount (per rider)
                                </label>
                                <input
                                    type="number"
                                    id="paymentAmount"
                                    name="paymentAmount"
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Enter amount"
                                    min="0"
                                    step="0.01"
                                    required
                                />

                                <p className="text-sm text-gray-600 mt-2">
                                    Platform Fee: {platformFee.toFixed(2)} {currency}
                                </p>
                                <p className="text-sm text-gray-600">
                                    (7.5% or 100 INR, whichever is lower)
                                </p>

                                <label className="block text-gray-700 text-sm font-bold mt-4" htmlFor="currency">
                                    Currency
                                </label>
                                <select
                                    id="currency"
                                    name="currency"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                >
                                    <option value="INR">INR - Indian Rupee</option>
                                    <option value="USD">USD - US Dollar</option>
                                    <option value="EUR">EUR - Euro</option>
                                    <option value="GBP">GBP - British Pound</option>
                                </select>

                                <button
                                    onClick={handleSetPayment}
                                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Set Payment
                                </button>

                                <p className="text-sm text-gray-600 mt-4">
                                    By adding payment, you agree to all terms and services and privacy policy of Ultra Rides.
                                </p>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                isClubOwner && (
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentAmount">
                            Payment Amount (per rider)
                        </label>
                        <input
                            type="number"
                            id="paymentAmount"
                            name="paymentAmount"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter amount"
                            min="0"
                            step="0.01"
                            required
                        />

                        <p className="text-sm text-gray-600 mt-2">
                            Platform Fee: {platformFee.toFixed(2)} {currency}
                        </p>
                        <p className="text-sm text-gray-600">
                            (7.5% or 100 INR, whichever is lower)
                        </p>

                        <label className="block text-gray-700 text-sm font-bold mt-4" htmlFor="currency">
                            Currency
                        </label>
                        <select
                            id="currency"
                            name="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        >
                            <option value="INR">INR - Indian Rupee</option>
                            <option value="USD">USD - US Dollar</option>
                            <option value="EUR">EUR - Euro</option>
                            <option value="GBP">GBP - British Pound</option>
                        </select>

                        <button
                            onClick={handleSetPayment}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            {ride.payment ? 'Save Payment' : 'Set Payment'}
                        </button>

                        <p className="text-sm text-gray-600 mt-4">
                            By adding payment, you agree to all terms and services and privacy policy of Ultra Rides.
                        </p>
                    </div>
                )
            )}
        </div>
    );
}

export default RidePayment;