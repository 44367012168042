import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../config/axiosConfig';
import { useAuth } from '../context/AuthContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RideHeader from './RideDetails/RideHeader';
import RideDetailsTab from './RideDetails/RideDetailsTab';
import ControlPointsTab from './RideDetails/ControlPointsTab';
import RouteTab from './RideDetails/RouteTab';
import RegisteredRidersTab from './RideDetails/RegisteredRidersTab';
import ErrorMessage from './Message';
import WaiverForm from './RideDetails/WaiverForm';
import CheckIn from './RideDetails/CheckIn';
import Joyride from 'react-joyride';
import ChatTab from './RideDetails/ChatTab';

const fetchRideDetails = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    return response.data;
};

function RideDetails() {
    const { rideId } = useParams();
    const { state } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [runJoyride, setRunJoyride] = useState(false);

    const { data: ride, error, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => fetchRideDetails(rideId),
        staleTime: 1000 * 60 * 5, // 5 minutes
        cacheTime: 1000 * 60 * 10, // 10 minutes
    });

    useEffect(() => {
        if (ride?.name) {
            document.title = `${ride.name} | Ultra Rides`;
        }
        if (ride?.user?.status === 'Joined' && !ride?.user?.waiver) {
            setRunJoyride(true);
        }
    }, [ride?.name, ride?.user?.waiver, ride?.user?.status]);

    useEffect(() => {
        const tabNames = ["details", "time-stations", "route", "riders", "waiver", "check-in", "chat"];
        const currentTab = location.hash.replace("#", "") || "details";
        setCurrentTabIndex(tabNames.findIndex(tab => currentTab.startsWith(tab)));
    }, [location]);

    const handleSave = async ({ field, value }) => {
        try {
            await axios.patch(`/rides/${ride?.rideId}`, { [field]: value, clubId: ride?.clubId });
            queryClient.invalidateQueries(['rideDetails', rideId]);
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            throw new Error("Cannot update ride details after registration closing date");
        }
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride details." />;

    const isUserRegistered = ride?.user?.status === 'Joined';
    let isClubOwner = ride?.user?.isClubOwner;
    if (state?.idTokenPayload?.email === 'rohanshar@gmail.com') {
        isClubOwner = true;
    }

    const tabNames = [
        "details",
        "time-stations",
        "route",
        "riders",
        "waiver",
        "check-in",
        "chat",
    ];
    const registeredRidersTabName = `Riders (${ride?.registeredRiders.length || 0})`;

    const joyrideSteps = [
        {
            target: ".step-2", // This targets the Payment section
            content: "Please complete the payment to proceed with the registration.",
            disableBeacon: true,
        },
        {
            target: ".react-tabs__tab-list li:nth-child(5)",
            content: "Please scroll down and submit the waiver to complete the registration.",
            disableBeacon: true,
        }
    ];

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8 space-y-6">
            <Joyride
                steps={joyrideSteps}
                run={runJoyride}
                continuous
                showProgress
                showSkipButton
                floaterProps={{ styles: { floater: { zIndex: 10000 } } }}
                callback={({ status }) => {
                    if (status === 'finished' || status === 'skipped') {
                        setRunJoyride(false);
                    }
                }}
                locale={{
                    last: "",
                    next: "Next",
                    back: "Back",
                    skip: "Skip",
                }}
            />
            {isClubOwner && (
                <div className="mt-6 text-center">
                    <button
                        onClick={() => navigate(`/rides/${rideId}/admin`)}
                        className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded"
                    >
                        Go to Admin Page
                    </button>
                </div>
            )}
            <RideHeader
                ride={ride}
                onSave={handleSave}
                isClubOwner={isClubOwner}
                state={state}
                isUserRegistered={isUserRegistered}
                rideId={rideId}
                queryClient={queryClient}
                navigate={navigate}
            />
            <Tabs
                selectedIndex={currentTabIndex}
                onSelect={index => navigate(`#${tabNames[index]}`)}
            >
                <TabList>
                    <Tab>Details</Tab>
                    <Tab>Time Stations</Tab>
                    <Tab>Route</Tab>
                    <Tab>{registeredRidersTabName}</Tab>
                    <Tab className={`${!ride?.user?.waiver ? 'bg-red-100 text-red-700 font-bold' : 'bg-green-100 text-green-700 font-bold'} react-tabs__tab`}>Waiver</Tab>
                    <Tab>Check-In</Tab>
                    <Tab>Chat</Tab>
                </TabList>
                <TabPanel>
                    <RideDetailsTab
                        ride={ride}
                        isClubOwner={isClubOwner}
                        onSave={handleSave}
                    />
                </TabPanel>
                <TabPanel>
                    <ControlPointsTab controlPoints={ride?.controlPoints || []} />
                </TabPanel>
                <TabPanel>
                    <RouteTab route={ride?.route || {}} />
                </TabPanel>
                <TabPanel>
                    <RegisteredRidersTab registeredRiders={ride?.registeredRiders || []} isClubOwner={isClubOwner} payment={ride?.payment} />
                </TabPanel>
                <TabPanel>
                    {isUserRegistered ? (
                        <WaiverForm onSubmit={() => queryClient.invalidateQueries(['rideDetails', rideId])} user={ride?.user || {}} />
                    ) : (
                        <div>Please register to see the waiver details.</div>
                    )}
                </TabPanel>
                <TabPanel>
                    {isUserRegistered ? (
                        <CheckIn ride={ride} controlPoints={ride?.controlPoints || []} waiverSubmitted={ride?.user?.waiver || false} />
                    ) : (
                        <div>Please register to see check-in details.</div>
                    )}
                </TabPanel>
                <TabPanel>
                    <ChatTab rideId={rideId} rideName={ride?.name} /> {/* Pass the rideName prop */}
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default RideDetails;