import React from 'react';

const TimingControlCheckbox = ({ rideData, handleChange }) => {
    return (
        <label className="flex items-center">
            <input
                type="checkbox"
                name="isTimeControlled"
                checked={rideData.isTimeControlled}
                onChange={handleChange}
                className="mr-2"
            />
            Is this a time-controlled ride?
        </label>
    );
};

export default TimingControlCheckbox;
