import React from 'react';
import axios from '../config/axiosConfig';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const fetchRides = async () => {
  const response = await axios.get('/rides');
  return response.data.rides; // Adjust this line based on your actual response structure
};

const getBadgeImage = (distance, timeControlType) => {
  let badgeName = '';
  console.log({ distance, timeControlType });

  if (timeControlType && timeControlType !== 'BRM' && timeControlType !== 'N/A') {
    badgeName = `${distance}kBadge_${timeControlType.toLowerCase()}`; // Use the timeControlType as a suffix if valid
  } else {
    badgeName = `${distance}kBadge`; // Default badge name if timeControlType is 'BRM' or 'N/A'
  }

  return `/${badgeName}.png`; // Return the full path to the badge image
};

const shouldShowTimingControlType = (timeControlType) => {
  return timeControlType && timeControlType !== 'BRM' && timeControlType !== 'N/A';
};

function RideList() {
  const { data: rides = [], error, isLoading } = useQuery({
    queryKey: ['rides'],
    queryFn: fetchRides,
  });

  if (isLoading) return <p className="text-center text-lg">Loading...</p>;
  if (error) return <p className="text-center text-lg text-error">Error fetching rides</p>;

  if (!Array.isArray(rides)) {
    return <p className="text-center text-lg text-error">Error: Expected an array of rides</p>;
  }

  return (
    <div className="min-h-screen bg-club-background py-8">
      <div className="max-w-4xl mx-auto form-container mt-8">
        <h1
          className="font-bold text-primary mb-8 text-center"
          style={{
            fontSize: 'clamp(1.5rem, 4vw, 2.5rem)', // Responsive font size
            margin: '0 auto',
            whiteSpace: 'nowrap',
          }}
        >
          Upcoming Ultra-Rides
        </h1>
        <div className="mb-6 text-center">
          <Link to="/myrides" className="text-club-primary hover:text-primary-dark text-lg font-semibold underline">
            View My Rides
          </Link>
        </div>
        {isLoading ? (
          <div className="space-y-6">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="p-6 bg-club-secondary rounded-lg shadow animate-pulse flex items-center">
                <div className="w-20 h-20 rounded-full bg-gray-200 mr-6"></div>
                <div className="flex-1 space-y-4">
                  <div className="h-6 bg-gray-200 rounded w-1/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ul className="space-y-6">
            {rides.map((ride) => (
              <li
                key={ride.rideId}
                className="p-6 bg-club-secondary rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
              >
                {/* Full row for ride name */}
                <div className="mb-4">
                  <Link to={`/rides/${ride.rideId}`} className="block text-2xl font-semibold text-club-accent hover:underline text-center md:text-left">
                    {ride.name}
                  </Link>
                </div>

                {/* Responsive layout for mobile and desktop */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                  {/* First column: logo */}
                  <div className="flex justify-center mb-4 md:mb-0">
                    <div className="w-24 h-24">
                      {ride.clubLogo ? (
                        <img src={ride.clubLogo} alt={`${ride.clubName} Logo`} className="w-full h-full rounded-full object-cover" />
                      ) : (
                        <div className="w-24 h-24 rounded-full bg-gray-200"></div>
                      )}
                    </div>
                  </div>

                  {/* Second column: ride details */}
                  <div className="col-span-1">
                    <div className="text-text-primary space-y-2 text-center md:text-left">
                      <p className="text-lg font-medium text-accent">
                        {new Date(ride.startDateTime).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </p>
                      <p>
                        <span className="font-semibold">Start Point:</span> {ride.startPoint}
                      </p>
                      <p>
                        {ride.clubName} - {ride.responsibleName}
                      </p>
                      <p className="inline-block bg-blue-100 text-blue-700 px-2 py-1 rounded-md text-sm font-semibold">
                        {ride.distance} km
                      </p>
                      {shouldShowTimingControlType(ride.timeControlType) && (
                        <p className={`inline-block bg-yellow-100 text-yellow-800 px-2 py-1 rounded-md text-sm font-semibold`}>
                          {ride.timeControlType}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Third column: badge */}
                  {getBadgeImage(ride.distance, ride.timeControlType) && (
                    <div className="flex justify-center mt-4 md:mt-0">
                      <div className="w-24 h-24">
                        <img
                          src={getBadgeImage(ride.distance, ride.timeControlType)}
                          alt={`${ride.distance}k Badge`}
                          className="w-full h-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default RideList;
