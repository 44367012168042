import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCheck, FaTimes } from 'react-icons/fa';

function RegisteredRidersTab({ registeredRiders, isClubOwner, payment }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedRiders, setSortedRiders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSortedByName, setIsSortedByName] = useState(false);
  const ridersPerPage = 25;

  useEffect(() => {
    console.log('Effect: Sorting registered riders');
    setSortedRiders([...registeredRiders]);
  }, [registeredRiders]);

  useEffect(() => {
    console.log('Effect: Reading initial page from URL');
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('p')) || 1;
    console.log('URL page param:', page);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [location.search]);

  useEffect(() => {
    console.log('Effect: Updating URL with current page', currentPage);
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('p')) || 1;
    if (page !== currentPage) {
      navigate(`#riders?p=${currentPage}`, { replace: true });
    }
  }, [currentPage, navigate, location.search]);

  const sortByName = () => {
    const sorted = [...sortedRiders].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortedRiders(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setIsSortedByName(true);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  const currentRiders = sortedRiders.slice(
    (currentPage - 1) * ridersPerPage,
    currentPage * ridersPerPage
  );

  const totalPages = Math.ceil(sortedRiders.length / ridersPerPage);

  return (
    <>
      <h2 className="text-2xl font-semibold text-accent mb-4">Registered Riders</h2>
      {registeredRiders.length === 0 ? (
        <p>No Riders registered yet.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-100 border rounded-lg overflow-hidden shadow-lg">
            <thead className="bg-primary text-white sticky top-0">
              <tr>
                <th className="py-2 px-4 border-b-2 border-primary text-left">#</th>
                <th className="py-2 px-4 border-b-2 border-primary text-left">Profile</th>
                <th
                  className="py-2 px-4 border-b-2 border-primary text-left cursor-pointer"
                  onClick={sortByName}
                  title="Click to sort by name"
                >
                  Name <span className="text-xs">&#x25B2;&#x25BC;</span>
                </th>
                <th className="py-2 px-4 border-b-2 border-primary text-left">URN</th>
                {isClubOwner && <th className="py-2 px-4 border-b-2 border-primary text-left">Email</th>}
                {payment && <th className="py-2 px-4 border-b-2 border-primary text-left">Paid</th>}
                {isClubOwner && <th className="py-2 px-4 border-b-2 border-primary text-left">Waiver Status</th>}
                <th className="py-2 px-4 border-b-2 border-primary text-left">Results</th>
              </tr>
            </thead>
            <tbody>
              {currentRiders.map((user, index) => (
                <tr key={index} className={`hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
                  <td className="py-2 px-4 border-b border-gray-200">{(currentPage - 1) * ridersPerPage + index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    <img
                      src={`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${user.urn}.jpg`}
                      alt={`Profile of ${user.name}`}
                      className="w-12 h-12 rounded-full"
                      onError={(e) => e.target.src = 'https://via.placeholder.com/150?text=No+Image'}
                    />
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    <Link to={`/athletes/${user.urn}/profile`} className="text-accent hover:underline">
                      {user.name}
                    </Link>
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200">{user.urn}</td>
                  {isClubOwner && <td className="py-2 px-4 border-b border-gray-200">{user.email}</td>}
                  {payment && (
                    <td className="py-2 px-4 border-b border-gray-200 text-center">
                      {user.payment ? <FaCheck className="text-success" /> : <FaTimes className="text-error" />}
                    </td>
                  )}
                  {isClubOwner && (
                    <td className="py-2 px-4 border-b border-gray-200 text-center">
                      {user.waiver ? <FaCheck className="text-success" /> : ''}
                    </td>
                  )}
                  <td className="py-2 px-4 border-b border-gray-200">{user.result || ''}</td> {/* Display result or empty */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-primary text-white' : 'bg-background'}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className="mt-4">
            <p><strong>Note:</strong></p>
            <ul>
              <li>DNS: Did Not Start</li>
              <li>DNF: Did Not Finish</li>
              <li>LF: Late Finish</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisteredRidersTab;