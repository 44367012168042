import React from 'react';
import { Link } from 'react-router-dom';

const UpcomingRides = ({ upcomingRides }) => {
  const getBadgeImage = (distance, timeControlType) => {
    console.log({distance})
    switch (distance) {
      case 100:
        return timeControlType !== 'BRM' ? '/100kBadge_challenge.png' : '/100kBadge.png';
      case 150:
        return '/150kBadge.png';
      case 200:
        return '/200kBadge.png';
      case 250:
        return timeControlType !== 'BRM' ? '/250Badge12h.png' : '/250kBadge.png';
      case 300:
        return '/300kBadge.png';
      default:
        return null;
    }
  };

  const shouldShowTimingControlType = (timeControlType) => {
    return timeControlType && timeControlType !== 'BRM';
  };

  return (
    <div className="w-full">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center md:text-left text-primary">Upcoming Rides</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {upcomingRides.map((ride) => (
          <div key={ride.RideID} className="p-4 rounded-lg bg-white shadow-md">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg sm:text-xl font-bold text-primary">
                  <Link to={`/rides/${ride.RideID}`} className="text-accent hover:underline">
                    {ride.rideName || 'Ride Name not available'}
                  </Link>
                </h3>
                <p className="text-textSecondary">
                  <strong>Distance:</strong> {ride.rideDistance} km
                  {shouldShowTimingControlType(ride.timeControlType) && (
                    <span className="ml-2 inline-block bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded">
                      {ride.timeControlType}
                    </span>
                  )}
                </p>
                <p className="text-textSecondary"><strong>Date:</strong> {new Date(ride.rideStartDateTime).toLocaleDateString()}</p>
                <p className="text-textSecondary"><strong>Club:</strong> {ride.ClubName || 'Club Name not available'}</p>
              </div>
              {getBadgeImage(ride.rideDistance, ride.timeControlType) && (
                <div className="flex-shrink-0 ml-4">
                  <div className="relative flex flex-col items-center">
                    <img src={getBadgeImage(ride.rideDistance, ride.timeControlType)} alt={`${ride.rideDistance}k Badge`} className="w-24 h-24 object-contain" />
                    <div className="absolute top-2 left-2 text-gray-900 bg-yellow-200 bg-opacity-70 px-2 py-0.5 rounded transform rotate-12 text-xs font-bold">Achievable</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingRides;
