import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import ErrorMessage from '../../Message';
import ControlPointsList from './ControlPointsList';

const fetchRideDetails = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    return response.data;
};

const updateRideDetails = async ({ rideId, clubId, updatedControlPoints, timeControlType, startDateTime }) => {
    const response = await axios.patch(`/rides/${rideId}`, { controlPoints: updatedControlPoints, clubId, timeControlType, startDateTime });
    return response.data;
};

function ControlPointsEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [controlPoints, setControlPoints] = useState([]);
    const [clubId, setClubId] = useState('');
    const [saveError, setSaveError] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState('');
    const [rideType, setRideType] = useState('');
    const [startDateTime, setStartDateTime] = useState('');
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);

    const { data: rideDetails, error, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => fetchRideDetails(rideId),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
    });

    useEffect(() => {
        if (rideDetails) {
            const formattedControlPoints = rideDetails.controlPoints.map(cp => {
                const [latitude, longitude] = cp.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()));
                return { ...cp, latitude, longitude };
            });
            setControlPoints(formattedControlPoints);
            setClubId(rideDetails.clubId);
            setRideType(rideDetails.timeControlType === 'Challenge' ? '20kmph' : '15kmph');
            setStartDateTime(rideDetails.startDateTime);
            if (rideDetails.user) {
                setIsClubOwner(rideDetails.user.isClubOwner);
                setHasAccess(rideDetails.user.isClubOwner);
            }
        }
    }, [rideDetails]);

    const mutation = useMutation({
        mutationFn: updateRideDetails,
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setSaveError('');
            setSaveSuccess('Control points saved successfully!');
            setIsSaving(false);
            setTimeout(() => setSaveSuccess(''), 3000);
        },
        onError: (error) => {
            if (error.response && error.response.data && error.response.data.message) {
                setSaveError(error.response.data.message);
            } else {
                setSaveError('An unexpected error occurred while updating control points.');
            }
            setIsSaving(false);
        }
    });

    const handleInputChange = (index, field, value) => {
        const updatedControlPoints = [...controlPoints];
        updatedControlPoints[index] = { ...updatedControlPoints[index], [field]: value };
        setControlPoints(updatedControlPoints);
    };

    const handleAddControlPoint = (index) => {
        const newControlPoint = {
            description: '',
            distance: 0,
            closeTime: '',
            latitude: 0,
            longitude: 0
        };
        const updatedControlPoints = [...controlPoints];
        updatedControlPoints.splice(index + 1, 0, newControlPoint);
        setControlPoints(updatedControlPoints);
    };

    const handleRemoveControlPoint = (index) => {
        const updatedControlPoints = controlPoints.filter((_, i) => i !== index);
        setControlPoints(updatedControlPoints);
    };

    const calculateNewCloseTime = (startTime, distance, speed) => {
        const durationInMs = (distance / speed) * 3600000;
        return new Date(startTime.getTime() + durationInMs);
    };

    const adjustCloseTimes = (newRideType) => {
        const speed = newRideType === '20kmph' ? 20 : 15;
        const updatedControlPoints = controlPoints.map((cp, index) => {
            if (index === 0) return cp;

            const startTime = new Date(startDateTime);
            const newCloseTime = calculateNewCloseTime(startTime, cp.distance, speed);

            console.log(`Control Point ${index + 1} Details:`);
            console.log(`  Start Time (Local): ${startTime.toString()}`);
            console.log(`  Distance: ${cp.distance} km`);
            console.log(`  Speed: ${speed} km/h`);
            console.log(`  New Close Time (Local): ${newCloseTime.toString()}`);

            return { ...cp, closeTime: newCloseTime.toISOString().slice(0, 16) };
        });

        setControlPoints(updatedControlPoints);
    };

    const handleRideTypeChange = (newRideType) => {
        console.log(`Ride type changed to: ${newRideType}`);
        setRideType(newRideType);
        adjustCloseTimes(newRideType);
    };

    const handleStartTimeChange = (e) => {
        const newStartTime = e.target.value;
        console.log(`Start Date and Time changed to: ${newStartTime}`);
        setStartDateTime(newStartTime);
    };

    const handleSaveClick = () => {
        if (!isClubOwner) {
            alert('You do not have permission to edit control points.');
            return;
        }
        setIsSaving(true);
        setSaveError('');
        setSaveSuccess('');

        const updatedControlPoints = controlPoints.map(cp => ({
            ...cp,
            gpsCoordinates: `${cp.latitude.toFixed(5)}, ${cp.longitude.toFixed(5)}`,
        }));

        const timeControlType = rideType === '20kmph' ? 'Challenge' : 'BRM';

        console.log('Saving updated control points:', updatedControlPoints);
        mutation.mutate({
            rideId,
            clubId,
            updatedControlPoints,
            timeControlType,
            startDateTime,
        });
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride details." />;
    if (!hasAccess) return <div className="text-center text-red-500">You don't have access to this page.</div>;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-8 space-y-6">
            <h1 className="text-3xl font-bold mb-4">Edit Control Points</h1>

            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Ride Type
                </label>
                <select
                    value={rideType}
                    onChange={(e) => handleRideTypeChange(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="15kmph">15 km/h (BRM)</option>
                    <option value="20kmph">20 km/h (Challenge)</option>
                </select>
            </div>

            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Start Date and Time
                </label>
                <input
                    type="datetime-local"
                    value={startDateTime}
                    onChange={handleStartTimeChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <ControlPointsList
                controlPoints={controlPoints}
                handleInputChange={handleInputChange}
                handleAddControlPoint={handleAddControlPoint}
                handleRemoveControlPoint={handleRemoveControlPoint}
                validationErrors={validationErrors}
                rideStartTime={startDateTime}
            />
            {saveError && <ErrorMessage message={saveError} />}
            {saveSuccess && <p className="text-green-500 text-sm">{saveSuccess}</p>}
            {isClubOwner && (
                <button
                    onClick={handleSaveClick}
                    disabled={isSaving}
                    className={`mt-4 ${isSaving ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded`}
                >
                    {isSaving ? 'Saving...' : 'Save Control Points'}
                </button>
            )}
        </div>
    );
}

export default ControlPointsEditor;