import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosConfig';
import { loadRazorpay, initRazorpay } from '../../utils/razorpayUtils';
import { useAuth } from '../../context/AuthContext';

function PaymentHandling({ isRegistered, rideId, queryClient, userPayment, payment }) {
  const { state } = useAuth();
  const [paymentStatus, setPaymentStatus] = useState(userPayment?.status === 'paid' ? 'Payment successful!' : null);

  useEffect(() => {
    const loadRazorpayScript = async () => {
      const res = await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');
      if (!res) {
        setPaymentStatus('Failed to load Razorpay SDK.');
      }
    };
    loadRazorpayScript();
  }, []);

  const handlePayment = async () => {
    if (!isRegistered) {
      alert("Please complete the registration (Step 1) first.");
      return;
    }

    try {
      const response = await axios.post('/payments/createOrder', {
        amount: payment.amount * 100, // Amount in paise (for INR) or the smallest currency unit
        currency: payment.currency,
        rideId: rideId,
      });

      if (response.data.orderId) {
        const Razorpay = await initRazorpay();

        if (Razorpay) {
          // Choose the correct key based on the email
          const razorpayKey = state.idTokenPayload.email === 'rohanshar@gmail.com'
            ? 'rzp_test_HaGbACYm1j6H2a' // Test key for Rohan's email
            : 'rzp_live_3twxUiPBqmGwwH'; // Live key for all other users

          const razorpayOptions = {
            key: razorpayKey, // Use the test or live key
            amount: payment.amount * 100,
            currency: payment.currency,
            order_id: response.data.orderId,
            name: "Ultra Rides",
            description: "Ride Payment",
            handler: function (response) {
              setPaymentStatus('Payment successful!');
              queryClient.invalidateQueries(['rideDetails', rideId]); // Invalidate query after successful payment
            },
            prefill: {
              email: state.idTokenPayload.email || '', // Prefill email from session
            },
            theme: {
              color: "#F37254",
            },
          };
          console.log({ razorpayOptions })
          const razorpayInstance = new Razorpay(razorpayOptions);
          razorpayInstance.open();
        } else {
          setPaymentStatus('Failed to load Razorpay SDK.');
        }
      } else {
        setPaymentStatus('Order creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Payment error:', error);
      setPaymentStatus('Payment failed. Please try again.');
    }
  };

  return (
    <div className="step-2 bg-white p-4 rounded-lg shadow-md mt-4">
      <h3 className="text-lg font-semibold">Step 2: Pay for the Ride</h3>
      {paymentStatus === 'Payment successful!' ? (
        <p className="text-green-600 font-semibold">Payment successful!</p>
      ) : (
        <button
          onClick={handlePayment}
          className="px-4 py-2 rounded bg-primary hover:bg-primary-dark text-white"
          disabled={!isRegistered}
        >
          Pay Now ({payment.amount} {payment.currency})
        </button>
      )}
      {paymentStatus && (
        <p className="mt-2 text-textSecondary">{paymentStatus}</p>
      )}
    </div>
  );
}

export default PaymentHandling;
