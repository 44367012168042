import React from 'react';
import RideDetailsContent from '../RideDetailsContent';

function RideDetailsContentComponent({ details }) {
  return (
    <div className="md:col-span-2">
      <RideDetailsContent content={details} />
    </div>
  );
}

export default RideDetailsContentComponent;
