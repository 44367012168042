import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBicycle, FaPlus, FaUserPlus } from 'react-icons/fa';
import axios from '../config/axiosConfig';
import LogoUploader from './ClubOwnerDashboard/LogoUploader';

function ClubOwnerDashboard() {
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [coreTeam, setCoreTeam] = useState([]);
  const [supporters, setSupporters] = useState([]);
  const [newCoreTeamMember, setNewCoreTeamMember] = useState("");
  const [newSupporter, setNewSupporter] = useState("");

  useEffect(() => {
    const fetchClub = async () => {
      try {
        const response = await axios.get('/clubs/myclub');
        setClub(response.data);
        setDescription(response.data.description || "");
        setCoreTeam(response.data.coreTeam || []);
        setSupporters(response.data.supporters || []);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("It looks like you haven't created a club yet.");
        } else {
          setError('Oops! There was an issue retrieving your club information. Please try again later.');
          console.error('Error fetching club:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchClub();
  }, []);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleAddCoreTeamMember = () => {
    if (newCoreTeamMember) {
      setCoreTeam([...coreTeam, newCoreTeamMember]);
      setNewCoreTeamMember("");
    } else {
      setError('Please enter a valid URN.');
    }
  };

  const handleAddSupporter = () => {
    if (newSupporter) {
      setSupporters([...supporters, newSupporter]);
      setNewSupporter("");
    } else {
      setError('Please enter a valid URN.');
    }
  };

  const handleRemoveCoreTeamMember = (index) => {
    setCoreTeam(coreTeam.filter((_, i) => i !== index));
  };

  const handleRemoveSupporter = (index) => {
    setSupporters(supporters.filter((_, i) => i !== index));
  };

  const handleRemoveLogo = async () => {
    try {
      await axios.patch(`/clubs/myclub`, { logo: null });
      setClub((prevClub) => ({ ...prevClub, logo: null }));
    } catch (error) {
      console.error('Error removing logo:', error);
      setError('Error removing logo.');
    }
  };

  const saveDescription = async () => {
    try {
      await axios.patch(`/clubs/myclub`, { description, coreTeam, supporters });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating club details:', error);
      setError('Error updating club details.');
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="min-h-screen flex flex-col items-center bg-background-light p-8">
      <div className="bg-white w-full max-w-4xl p-4 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-primary mb-4">Club Owner Dashboard</h1>
        {error ? (
          <div className="text-center">
            <p className="text-red-500 mb-4">{error}</p>
            <Link to="/clubs/new" className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all">
              Create New Club
            </Link>
          </div>
        ) : (
          club && (
            <div className="text-center">
              <h2 className="text-xl font-semibold text-primary mb-4">Club Name: {club.name}</h2>
              <div className="mb-4">
                {club.logo ? (
                  <div>
                    <img src={club.logo} alt="Club Logo" className="w-32 h-32 mx-auto rounded-full object-cover" />
                    <button
                      onClick={handleRemoveLogo}
                      className="mt-2 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700"
                    >
                      Remove Logo
                    </button>
                  </div>
                ) : (
                  <LogoUploader clubId={club.clubId} setClub={setClub} />
                )}
              </div>
              {isEditing ? (
                <div>
                  <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    className="w-full p-2 border rounded"
                  />
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold">Core Team</h3>
                    <ul>
                      {coreTeam.map((member, index) => (
                        <li key={index} className="flex justify-between">
                          {member}
                          <button
                            onClick={() => handleRemoveCoreTeamMember(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="flex items-center mt-2">
                      <input
                        type="text"
                        value={newCoreTeamMember}
                        onChange={(e) => setNewCoreTeamMember(e.target.value)}
                        placeholder="Enter URN for Core Team member"
                        className="w-full p-2 border rounded mr-2"
                      />
                      <button
                        onClick={handleAddCoreTeamMember}
                        className="bg-primary text-white py-2 px-4 rounded"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold">Supporters</h3>
                    <ul>
                      {supporters.map((supporter, index) => (
                        <li key={index} className="flex justify-between">
                          {supporter}
                          <button
                            onClick={() => handleRemoveSupporter(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="flex items-center mt-2">
                      <input
                        type="text"
                        value={newSupporter}
                        onChange={(e) => setNewSupporter(e.target.value)}
                        placeholder="Enter URN for Supporter"
                        className="w-full p-2 border rounded mr-2"
                      />
                      <button
                        onClick={handleAddSupporter}
                        className="bg-primary text-white py-2 px-4 rounded"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={saveDescription}
                    className="bg-primary text-white py-2 px-4 rounded mt-2"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div>
                  <p>{description}</p>
                  <p className="mt-4">Core Team: {coreTeam.join(', ')}</p>
                  <p className="mt-4">Supporters: {supporters.join(', ')}</p>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="bg-primary text-white py-2 px-4 rounded mt-4"
                  >
                    Edit
                  </button>
                </div>
              )}
              <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                <Link to="/owner-dashboard/rides" className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all flex items-center justify-center space-x-2">
                  <FaBicycle className="mr-2" /> <span>Rides</span>
                </Link>
                <Link to={`/clubs/${club.clubId}/rides/new`} className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all flex items-center justify-center space-x-2">
                  <FaPlus className="mr-2" /> <span>Create Ride</span>
                </Link>
                <Link to="/clubs/ClubPaymentsOnboarding" className="bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition-all flex items-center justify-center space-x-2">
                  <FaUserPlus className="mr-2" /> <span>Onboard Club Payments</span>
                </Link>
              </div>
              <div className="mt-4">
                <Link to={`/clubs/f7cb8d97-571e-4a5d-968f-62a4878784fd`} className="bg-secondary text-white py-2 px-4 rounded hover:bg-secondary-dark transition-all">
                  Go to Club Public Page
                </Link>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default ClubOwnerDashboard;
