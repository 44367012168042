import React, { useState } from 'react';
import axios from '../../../config/axiosConfig';
import { useParams } from 'react-router-dom';

function CoverImageComponent({ coverImage, isClubOwner, onSave, ride }) {
  const [editing, setEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(coverImage);
  const { rideId } = useParams();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setEditing(true);
    }
  };

  const handleUpload = async () => {
    if (!newImage) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const base64Image = reader.result.split(',')[1];
        const response = await axios.patch(`/rides/${rideId}`, { imageBase64: base64Image, clubId: ride.clubId });
        await onSave({ field: 'coverImage', value: response.data.coverImageUrl });
        setEditing(false);
        setNewImage(null);
        setPreviewUrl(response.data.coverImageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
    reader.readAsDataURL(newImage);
  };

  return (
    <div className="w-full col-span-1 md:col-span-2">
      {editing ? (
        <div>
          <img src={previewUrl} alt="Cover Preview" className="w-full h-auto mb-4 shadow-md" />
          <input type="file" onChange={handleFileChange} />
          <button
            onClick={handleUpload}
            className="mt-2 px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 text-white"
          >
            Upload
          </button>
          <button
            onClick={() => setEditing(false)}
            className="mt-2 px-4 py-2 rounded bg-gray-400 text-white"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div onClick={() => isClubOwner && setEditing(true)}>
          <img src={previewUrl} alt="Ride Cover" className="w-full h-auto mb-4 shadow-md cursor-pointer" />
        </div>
      )}
    </div>
  );
}

export default CoverImageComponent;
