import React, { useState, useEffect } from 'react';
import StartPointComponent from './RideDetailsTab/StartPointComponent';
import RideDetailsContentComponent from './RideDetailsTab/RideDetailsContentComponent';
import ResponsiblePersonComponent from './RideDetailsTab/ResponsiblePersonComponent';
import CoverImageComponent from './RideDetailsTab/CoverImageComponent';
import axios from '../../config/axiosConfig';
import { loadRazorpay, initRazorpay } from '../../utils/razorpayUtils';

function RideDetailsTab({ ride, isClubOwner, onSave }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const initializeRazorpay = async () => {
      await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');
    };
    initializeRazorpay();
  }, []);

  const handleSave = async ({ field, value }) => {
    try {
      await onSave({ field, value });
      setErrorMessage(""); // Clear error message on successful save
    } catch (error) {
      setErrorMessage(error.message); // Set error message on failure
    }
  };



  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {errorMessage && (
        <div className="col-span-1 md:col-span-2 bg-error text-error p-2 rounded-lg">
          {errorMessage}
        </div>
      )}
      <StartPointComponent
        startPoint={ride.startPoint}
        isClubOwner={isClubOwner}
        onSave={handleSave}
      />
      <div className="bg-background p-4 rounded-lg shadow-md">
        <label className="font-semibold text-textSecondary">Start Time:</label>
        <p className="text-textPrimary">
          {new Date(ride.startDateTime).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </p>
      </div>
      <div className="bg-background p-4 rounded-lg shadow-md">
        <label className="font-semibold text-textSecondary">Distance:</label>
        <p className="text-textPrimary">{ride.distance} km</p>
      </div>
      <div className="bg-background p-4 rounded-lg shadow-md">
        <label className="font-semibold text-textSecondary">Time Controlled:</label>
        <p className="text-textPrimary">{ride.isTimeControlled ? 'Yes' : 'No'}</p>
      </div>
      <CoverImageComponent
        coverImage={ride.coverImage}
        isClubOwner={isClubOwner}
        onSave={handleSave}
        ride={ride}
      />
      <RideDetailsContentComponent details={ride.details} isClubOwner={isClubOwner} onSave={handleSave} />
      <ResponsiblePersonComponent
        responsibleName={ride.responsibleName}
        responsibleEmail={ride.responsibleEmail}
        responsiblePhone={ride.responsiblePhone}
        whatsappGroupUrl={ride.whatsappGroupUrl}
        isClubOwner={isClubOwner}
        onSave={handleSave}
      />
    </div>
  );
}

export default RideDetailsTab;
