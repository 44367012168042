import React, { useState } from 'react';
import { FaCalendarAlt, FaClock, FaRegCalendarCheck } from 'react-icons/fa';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import RideRegistration from './RideRegistration';
import PaymentHandling from './PaymentHandling';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function RideHeader({ ride, isClubOwner, onSave, state, isUserRegistered, rideId, queryClient, navigate }) {
  const [editing, setEditing] = useState(false);
  const [rideName, setRideName] = useState(ride.name);
  const [isRegistered, setIsRegistered] = useState(isUserRegistered); // Track registration status

  const handleFieldClick = () => {
    if (isClubOwner) {
      setEditing(true);
    }
  };

  const handleFieldChange = (e) => {
    setRideName(e.target.value);
  };

  const handleFieldBlur = async () => {
    await onSave({ field: 'name', value: rideName });
    setEditing(false);
  };

  const handleRegister = async () => {
    if (!state.isAuthenticated) {
      navigate(`/create-account?redirect=/rides/${rideId}`);
      return;
    }

    try {
      const response = await axios.post(`/rides/${rideId}/register`);
      if (response.status === 200) {
        queryClient.invalidateQueries(['rideDetails', rideId]);
        navigate('#waiver'); // Navigate to waiver tab after successful registration
        setIsRegistered(true); // Set registration status to true
      } else {
        console.error('Failed to register for the ride.');
      }
    } catch (error) {
      console.error('Error registering for ride:', error);
    }
  };

  const handleUnregister = async () => {
    if (!state.isAuthenticated) {
      navigate(`/create-account?redirect=/rides/${rideId}`);
      return;
    }

    const confirmUnregister = window.confirm('Are you sure you want to unregister from this ride?');
    if (!confirmUnregister) {
      return;
    }

    try {
      const response = await axios.post(`/rides/${rideId}/unregister`);
      if (response.status === 200) {
        queryClient.invalidateQueries(['rideDetails', rideId]);
        setIsRegistered(false); // Set registration status to false
      } else {
        console.error('Failed to unregister from the ride.');
      }
    } catch (error) {
      console.error('Error unregistering from ride:', error);
    }
  };

  return (
    <div className="bg-background text-textPrimary p-6 rounded-lg shadow-md border border-gray-300">
      {editing ? (
        <input
          type="text"
          value={rideName}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          className="text-textPrimary border border-gray-300 rounded p-1"
          autoFocus
        />
      ) : (
        <h1
          className="text-4xl font-bold mb-4 cursor-pointer"
          onClick={handleFieldClick}
          title={isClubOwner ? 'Click to edit' : ''}
        >
          {capitalizeFirstLetter(rideName)}
        </h1>
      )}
      <div className="flex items-center space-x-2 mb-4">
        <div className="bg-gray-300 text-textPrimary font-semibold py-1 px-3 rounded-full text-sm">
          Not a Race
        </div>
        {isClubOwner && (
          <div className="bg-success text-success-dark font-semibold py-1 px-3 rounded-full text-sm">
            Club Owner
          </div>
        )}
      </div>
      <div className="space-y-4">
        <div className="flex items-center">
          <FaCalendarAlt className="mr-2 text-textSecondary" />
          <div className="flex flex-col sm:flex-row sm:items-center">
            <span className="font-semibold text-textSecondary sm:mr-2">Start Date & Time:</span>
            <span className="font-normal text-textPrimary">{format(new Date(ride.startDateTime), 'EEEE, MMMM do, yyyy, h:mm a')}</span>
          </div>
        </div>
        <div className="flex items-center">
          <FaRegCalendarCheck className="mr-2 text-textSecondary" />
          <div className="flex flex-col sm:flex-row sm:items-center">
            <span className="font-semibold text-textSecondary sm:mr-2">Club:</span>
            <Link to={`/clubs/${ride.clubId}`} className="text-accent hover:underline text-xl font-semibold">
              {ride.clubName}
            </Link>
          </div>
        </div>
        <div className="flex items-center">
          <FaClock className="mr-2 text-textSecondary" />
          <div className="flex flex-col sm:flex-row sm:items-center">
            <span className="font-semibold text-textSecondary sm:mr-2">Registration Closes:</span>
            <span className="font-normal text-textPrimary">{ride.registrationClosingDateTime ? format(new Date(ride.registrationClosingDateTime), 'EEEE, MMMM do, yyyy, h:mm a') : 'N/A'}</span>
          </div>
        </div>
        {ride.payment && (
          <div className="flex items-center">
            <div className="font-semibold text-textSecondary sm:mr-2">Ride Cost:</div>
            <div className="font-normal text-textPrimary">
              {ride.payment.amount} {ride.payment.currency}
            </div>
          </div>
        )}
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Steps to Participate</h2>
        <div className="space-y-4">
          <div className="step-1 bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">Step 1: Register for the Ride</h3>
            <RideRegistration
              state={state}
              handleRegister={handleRegister}
              handleUnregister={handleUnregister}
              isUserRegistered={isRegistered}
              registrationClosingDateTime={ride.registrationClosingDateTime}
            />
          </div>
          {ride.payment && (
            <PaymentHandling isRegistered={isRegistered} rideId={rideId} queryClient={queryClient} userPayment={ride?.user?.payment} payment={ride.payment} />
          )}
        </div>
      </div>
    </div>
  );
}

export default RideHeader;
