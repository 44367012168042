import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function MasterAdminPage() {
    const location = useLocation();

    return (
        <div className='container mx-auto p-4'>
            <h1 className='text-3xl font-bold mb-6'>Master Admin Dashboard</h1>
            <ul className='list-disc pl-6'>
                <li className='mb-4'>
                    <Link to={`${location.pathname}/riders`} className='text-xl text-blue-500 hover:underline'>
                        Admin Riders List
                    </Link>
                </li>
                <li className="mb-4">
                    <Link to={`${location.pathname}/ride-payment`} className="text-xl text-blue-500 hover:underline">
                        Ride Payment
                    </Link>
                </li>
                <li className="mb-4">
                    <Link to={`${location.pathname}/ride-information`} className="text-xl text-blue-500 hover:underline">
                        Edit Ride Information
                    </Link>
                </li>
                <li className="mb-4">
                    <Link to={`${location.pathname}/ride-details`} className="text-xl text-blue-500 hover:underline">
                        Edit Ride Details
                    </Link>
                </li>
                <li className="mb-4">
                    <Link to={`${location.pathname}/control-points`} className="text-xl text-blue-500 hover:underline">
                        Edit Control Points (under development)
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default MasterAdminPage;
