import React, { useEffect, useState } from 'react';
import { calculateControlTimes } from '../../lib/timeControlUtils';
import GPSInput from './GPSInput'; // Import the new component

const TimingControls = ({ rideData, handleChange }) => {
  const [localControlPoints, setLocalControlPoints] = useState(
    rideData.controlPoints.map(control => ({
      ...control,
      gpsCoordinates: control.gpsCoordinates || ''
    }))
  );

  const convertToLocalTime = (time) => {
    if (time === 'Invalid time') {
      return ''; // Return empty string for invalid times
    }
    const localTime = new Date(time);
    if (isNaN(localTime.getTime())) {
      return ''; // Return empty string for invalid dates
    }
    const offset = localTime.getTimezoneOffset();
    return new Date(localTime.getTime() - offset * 60000).toISOString().slice(0, 16);
  };

  useEffect(() => {
    const updatedControls = localControlPoints.map((control) => {
      const [closeTime, totalTime] = calculateControlTimes(
        rideData.distance,
        control.distance,
        rideData.timeControlType,
        rideData.startDateTime
      );
      return { ...control, closeTime: convertToLocalTime(closeTime), totalTime };
    });
    setLocalControlPoints(updatedControls);
    handleChange({ target: { name: 'controlPoints', value: updatedControls } });
  }, [rideData.distance, rideData.timeControlType, rideData.startDateTime]);

  const handleDistanceChange = (index, value) => {
    const updatedControls = localControlPoints.map((control, i) => {
      if (i === index) {
        const [closeTime, totalTime] = calculateControlTimes(
          rideData.distance,
          value,
          rideData.timeControlType,
          rideData.startDateTime
        );
        return { ...control, distance: value, closeTime: convertToLocalTime(closeTime), totalTime };
      }
      return control;
    });
    setLocalControlPoints(updatedControls);
    handleChange({ target: { name: 'controlPoints', value: updatedControls } });
  };

  const handleControlPointsChange = (e) => {
    const { value } = e.target;
    const count = parseInt(value, 10);
    if (isNaN(count) || count < rideData.minimumControlPoints) return;

    const newControlPoints = Array.from({ length: count }, (_, i) => (
      localControlPoints[i + 1] || {
        distance: '',
        openTime: '',
        closeTime: '',
        description: '',
        gpsCoordinates: ''
      }
    ));
    const startControl = localControlPoints[0];
    const endControl = localControlPoints[localControlPoints.length - 1];
    setLocalControlPoints([startControl, ...newControlPoints, endControl]);
    handleChange({ target: { name: 'controlPoints', value: [startControl, ...newControlPoints, endControl] } });
  };

  const handleDescriptionChange = (index, value) => {
    const updatedControls = localControlPoints.map((control, i) => {
      if (i === index) {
        return { ...control, description: value };
      }
      return control;
    });
    setLocalControlPoints(updatedControls);
    handleChange({ target: { name: 'controlPoints', value: updatedControls } });
  };

  const handleGPSCoordinatesChange = (index, value) => {
    const updatedControls = localControlPoints.map((control, i) => {
      if (i === index) {
        return { ...control, gpsCoordinates: value };
      }
      return control;
    });
    setLocalControlPoints(updatedControls);
    handleChange({ target: { name: 'controlPoints', value: updatedControls } });
  };

  useEffect(() => {
    const startControl = {
      distance: 0,
      closeTime: rideData.startDateTime,
      totalTime: '0:00',
      description: 'Start',
      gpsCoordinates: rideData.startCoordinates || ''
    };
    const [endCloseTime, endTotalTime] = calculateControlTimes(rideData.distance, rideData.distance, rideData.timeControlType, rideData.startDateTime);
    const endControl = {
      distance: rideData.distance,
      closeTime: convertToLocalTime(endCloseTime),
      totalTime: endTotalTime,
      description: 'End',
      gpsCoordinates: rideData.endCoordinates || ''
    };
    const updatedControls = [startControl, ...localControlPoints.slice(1, localControlPoints.length - 1), endControl];
    setLocalControlPoints(updatedControls);
    handleChange({ target: { name: 'controlPoints', value: updatedControls } });
  }, [rideData.distance, rideData.timeControlType, rideData.startDateTime]);

  const validateDistance = (distance) => {
    if (isNaN(distance) || distance < 0 || distance > rideData.distance) {
      return 'Invalid distance';
    }
    return '';
  };

  const handleDistanceBlur = (index, value) => {
    const error = validateDistance(value);
    if (error) {
      alert(error); // Show error to the user
    }
  };

  return (
    <div className="mt-4">
      <div className="section">
        <h3 className="section-heading">Time Control Type</h3>
        <div className="flex items-center mb-2">
          <label className="mr-2">Time Control Type:</label>
          <select
            name="timeControlType"
            value={rideData.timeControlType}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded"
          >
            <option value="">Select</option>
            <option value="BRM">BRM</option>
            <option value="Populaire">Populaire</option>
          </select>
        </div>
      </div>
      <div className="section">
        <h3 className="section-heading">Number of Control Points</h3>
        <div className="flex items-center mb-2">
          <label className="mr-2">Number of Control Points:</label>
          <input
            type="text"
            value={localControlPoints.length - 2}
            onChange={handleControlPointsChange}
            className="p-2 border border-gray-300 rounded"
            min={rideData.minimumControlPoints}
            maxLength={2}
            style={{ appearance: 'textfield', width: '3em' }}
            onWheel={(e) => e.target.blur()} // Prevent changing on scroll
          />
        </div>
      </div>
      {localControlPoints.map((control, index) => (
        <div key={index} className="section mb-4 p-2 border border-gray-600 rounded bg-white">
          <h3 className="section-heading">{index === 0 ? 'Start' : index === localControlPoints.length - 1 ? 'End' : `CP ${index}`}</h3>
          <div className="flex items-center mb-2">
            <label className="mr-2">Distance (km):</label>
            <input
              type="text"
              value={control.distance}
              onChange={(e) => handleDistanceChange(index, e.target.value)}
              onBlur={(e) => handleDistanceBlur(index, e.target.value)} // Validate on blur
              className="p-2 border border-gray-300 rounded"
              style={{ appearance: 'textfield', width: '4em' }}
              maxLength={4}
              onWheel={(e) => e.target.blur()} // Prevent changing on scroll
              readOnly={index === 0 || index === localControlPoints.length - 1} // Readonly for Start and End
              disabled={index === 0} // Disabled for Start
            />
          </div>
          <GPSInput
            value={control.gpsCoordinates}
            onChange={(value) => handleGPSCoordinatesChange(index, value)}
          />
          <div className="flex items-center mb-2">
            <label className="mr-2">Close Time:</label>
            <input
              type="datetime-local"
              value={control.closeTime}
              className="p-2 border border-gray-300 rounded"
              readOnly
              disabled
            />
          </div>
          <div className="flex items-center mb-2 subtext">
            <label className="mr-2">Total Time into Ride: {control.totalTime}</label>
          </div>
          {index !== 0 && index !== localControlPoints.length - 1 && (
            <div className="flex items-center mb-2">
              <label className="mr-2">Description:</label>
              <textarea
                value={control.description}
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                className="p-2 border border-gray-300 rounded"
                rows={4} // Adjust the number of rows to make the text box bigger
                style={{ width: '100%' }} // Adjust width as needed
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TimingControls;
